<section class="first-section orgn-half orgn-hm-landing">
  <div class="orgn-half p-3 p-md-5 w100 orgn-bg-dark text-white  align-items-center justify-content-center d-flex">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <h1 class="text-center hide-on-init" animateOnScroll animationName="animated fadeInDown">Originate For Good
          </h1>
          <span class="orgn-divider orgn-bg-white my-4" animateOnScroll animationName="visible"></span>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="d-block">
  <div class="row justify-content-center">
    <div class="col-12 col-md-8 py-5">
      <h2 class="hide-on-init" animateOnScroll animationName="animated fadeInDown">Good Creativity Can Change The World
        For Good</h2>
      <p class="hide-on-init py-4" animateOnScroll animationName="animated fadeInUp">The Originate For Good family tree
        includes the following strategic initiatives:</p>
      <h4 animateOnScroll animationName="animated fadeInUp">Originate for Impact</h4>
      <p animateOnScroll animationName="animated fadeInUp">The objective is for Originators to be a Force For Good by
        creating ideas/assets for our clients that can positively impact South Africa for good. We do this by
        facilitating creative services to select clients NPO's & Social Impact Enterprises. We provide creative services
        including; integrated campaigns, creative, entertainment marketing, social content, content production, brand
        development, digital assets and services for the impact community.</p>
      <p animateOnScroll animationName="animated fadeInUp"><a class="link" routerLink="/gdoh"><b>Originators actively supports our nation’s efforts to beat
        Covid-19. See our work for the Gauteng Department of Health.</b></a></p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-12 col-md-6 pb-5">
      <video id="video_main" class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9" controls
        poster="https://i3.ytimg.com/vi/7RJhHttnOGs/maxresdefault.jpg" preload="auto" width="640" height="264">
        <source src="assets/video/Global_Citizen_Fest.mov" type="video/mp4" />
      </video>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-12 col-md-8 py-5">
      <h4>The Originators Internship Program</h4>
      <p>We support talented young creatives to develop the critical skills needed to elevate their careers and get them
        to be employable, Thereby filling the gap of lack of skills in Africa. </p>
      <a class="btn" routerLink="/intern">Find out more.</a>
    </div>
  </div>
</section>
