import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  data: any;
  constructor(
    private router: Router,
    private http: HttpClient
  ) { 
    this.data = {
      name: '',
      email: '',
      subject: '',
      message: '',
    }
  }

  ngOnInit() {
  }

  onSubmit(f: NgForm) {
    console.log(f.value);
    console.log(f.valid);
    this.http.post('https://originators.tv/old/index.php', JSON.stringify(this.data))
        .subscribe(
          response => this.router.navigateByUrl('/thanks')
        );
  }
}
