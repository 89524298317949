import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Product } from './product';
import { PRODUCTS } from './mock-products';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  getProducts(): Observable<Product[]> {
    return of(PRODUCTS.map((item, index) => {
      item.id = index;
      return item;
    }));
  }

  getProductById(id: number): Observable<Product> {
    return of(PRODUCTS.map((item, index) => {
      item.id = index;
      return item;
    }).find((element, index) => {
      return index === id;
    }));
  }

  getProductTotal(): Observable<number> {
    return of(PRODUCTS.length);
  }

  cleanURL(oldURL): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  getImageFromLink(videoUrl: string): string {
    if (videoUrl) {
      const code = videoUrl.match(/embed\/(.*)$/);
      if (code[1]) {
        return `https://i3.ytimg.com/vi/${code[1]}/maxresdefault.jpg`;
      }
    }
    return '';
  }
}
