import { Component, OnInit } from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-intern',
  templateUrl: './intern.component.html',
  styleUrls: ['./intern.component.scss'],
})
export class InternComponent implements OnInit {

  programs = [
    'Motion Design',
    'Digital Design',
    'Copywriting',
    'Video Editing',
    'Animation ',
    'Production',
  ];

  constructor() {}

  ngOnInit() {
  }

}
