import { Component, NgModule } from '@angular/core';
import { Lightbox } from 'angular2-lightbox';
import videojs from 'video.js';

@NgModule({
  imports: [ Lightbox ]
})

@Component({
  selector: 'app-gdoh',
  templateUrl: './gdoh.component.html',
  styleUrls: ['./gdoh.component.scss']
})

export class GdohComponent {
  _album = [];
  constructor(private _lightbox: Lightbox) {
    for (let i = 1; i <= 9; i++) {
      const src = 'assets/gallery/' + i + '.jpeg';
      const caption = '';
      const thumb = 'assets/gallery/' + i + '.jpeg';
      const album = {
         src: src,
         caption: caption,
         thumb: thumb
      };
      this._album.push(album);
    }
  }

  ngAfterViewInit() {
    // ID with which to access the template's video element
    const el = 'video_main';
    const els = document.getElementsByClassName('video-js');
    for (let i = 0; i < els.length; i++) {
      videojs(els[i], {}, function () {

        // Store the video object
        const myPlayer = this, id = myPlayer.id();
        // Make up an aspect ratio
        const aspectRatio = 264 / 640;
        // internal method to handle a window resize event to adjust the video player
        function resizeVideoJS() {
          const width = document.getElementById(id).parentElement.offsetWidth;
          if (!!myPlayer) {
            myPlayer.width(width);
            myPlayer.height(width * aspectRatio);
          }
        }
        // Initialize resizeVideoJS()
        resizeVideoJS();
        // Then on resize call resizeVideoJS()
        // window.onresize = resizeVideoJS;
      });
    }
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._album, index);
  }
}
