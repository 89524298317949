import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LightboxModule } from 'angular2-lightbox';

import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { AboutComponent } from './about/about.component';
import { AppRoutingModule } from './/app-routing.module';
import { WorkComponent } from './work/work.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { DetailComponent } from './detail/detail.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { InternComponent } from './intern/intern.component';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import { TemplateContentComponent } from './template-content/template-content.component';
import { TemplateCampaignComponent } from './template-campaign/template-campaign.component';
import { TemplateDefaultComponent } from './template-default/template-default.component';
import { TemplateContentBasicComponent } from './template-content-basic/template-content-basic.component';
import { OriginateForGoodComponent } from './originate-for-good/originate-for-good.component';
import { GdohComponent } from './gdoh/gdoh.component';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    AboutComponent,
    WorkComponent,
    ContactComponent,
    FooterComponent,
    DetailComponent,
    ThankYouComponent,
    InternComponent,
    TermsComponent,
    PolicyComponent,
    TemplateContentComponent,
    TemplateCampaignComponent,
    TemplateDefaultComponent,
    TemplateContentBasicComponent,
    OriginateForGoodComponent,
    GdohComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule.forRoot(),
    AppRoutingModule,
    AnimateOnScrollModule.forRoot(),
    HttpClientModule,
    FormsModule,
    LightboxModule,
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
