import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SafeUrl } from '@angular/platform-browser';
import { ProductService } from '../shared/product.service';
import { Product } from '../shared/product';
import { Client } from './client';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  products: Product[];
  current_url: SafeUrl;

  clients: Client[] = [{
    name: 'bet',
    link: 'http://www.betafrica.tv/',
    img: 'assets/img/clients/bet.gif',
  },{
    name: 'vusu',
    link: 'https://vuzu.dstv.com/South/home',
    img: 'assets/img/clients/vuzu_amp.gif',
  },{
    name: 'channel',
    link: 'https://channelo.dstv.com/',
    img: 'assets/img/clients/channel-0.gif',
  },{
    name: 'mzansi',
    link: 'http://mzansimagic.dstv.com/channel/mzansi-magic-music-321_26',
    img: 'assets/img/clients/mzansi-magic.gif',
  },{
    name: 'mzansi',
    link: 'http://mzansimagic.dstv.com/channel/mzansi-magic-music-321_26',
    img: 'assets/img/clients/mzansi-music.gif',
  },{
    name: 'crimson',
    link: 'http://www.crimsonmultimedia.com/cinemas/',
    img: 'assets/img/clients/crimson.gif',
  },{
    name: 'kwese',
    link: 'https://www.kwese.com/',
    img: 'assets/img/clients/kwese.gif',
  },{
    name: 'fox',
    link: 'http://www.foxafrica.com/',
    img: 'assets/img/clients/fox-life.gif',
  },{
    name: 'trace',
    link: 'http://trace.tv/',
    img: 'assets/img/clients/trace-africa.gif',
  },{
    name: 'trace',
    link: 'http://trace.tv/',
    img: 'assets/img/clients/trace-urban.gif',
  },{
    name: '1magic',
    link: 'https://onemagic.dstv.com/South/home',
    img: 'assets/img/clients/one-magic.gif',
  },{
    name: 'black',
    link: 'https://www.black.co.za/',
    img: 'assets/img/clients/black.gif',
  },{
    name: 'dstv',
    link: 'https://www.dstv.com/',
    img: 'assets/img/clients/dstv.gif',
  },{
    name: 'mnet',
    link: 'https://m-net.dstv.com/channel/m-net-movies-premiere_4',
    img: 'assets/img/clients/mnet-movies.jpg',
  },{
    name: 'ummahtv',
    link: 'http://ummahtv.net',
    img: 'assets/img/clients/ummahtv.gif',
  },{
    name: 'yfm',
    link: 'http://www.yfm.co.za',
    img: 'assets/img/clients/yfm.jpg',
  }
  ,{
    name: 'Sof n\' free',
    link: 'https://www.sofnfree.mobi',
    img: 'assets/img/clients/sofnfree.jpg',
  }
  ,{
    name: 'V Digital Bank',
    link: 'https://vbank.ng',
    img: 'assets/img/clients/vbank.jpg',
  }
  ,{
    name: 'Gauteng Department of Health',
    link: 'https://www.gauteng.gov.za/Home',
    img: 'assets/img/clients/health.jpg',
  }
];

  constructor(
    private productService: ProductService,
    private router: Router,
  ) { 
    this.products = [];
  }

  ngOnInit() {
    this.getProducts();
  }

  getProducts(): Product[] {
    if ( this.products.length > 0) {
      return this.products;
    }
    this.productService.getProducts()
      .subscribe(list => {
        this.products = (list[0].id === 0) ? list.slice(-6) : list.reverse().slice(-6);
        this.products.reverse();

        for (let i = 0; i <= this.products.length; i++) {
          if (this.products[i] && !this.products[i].image && this.products[i].video) {
            this.products[i].image = this.productService.getImageFromLink(this.products[i].video);
          }
        }
      });
  }

  viewProduct(id: number) {
    let url: string = '/detail/' + id
    this.router.navigateByUrl(url);
  }

  viewWork() {
    this.router.navigateByUrl('/work');
  }
}
