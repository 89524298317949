<section class="first-section orgn-hm-landing">
  <div class="p-3 p-md-5 orgn-full w100 text-white  align-items-center justify-content-center d-flex">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <h1 class="text-center hide-on-init" animateOnScroll animationName="animated fadeInDown">Thank you.</h1>
          <span class="orgn-divider orgn-bg-white my-4" animateOnScroll animationName="visible"></span>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-6">
          <p class="hide-on-init text-center" animateOnScroll animationName="animated fadeIn">
              Your message was sent successfully.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
