<section class="first-section orgn-hm-landing">
  <div class="p-3 p-md-5 w100 text-white  align-items-center justify-content-center d-flex">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <h1 class="text-center hide-on-init" animateOnScroll animationName="animated fadeInDown">Contact</h1>
          <span class="orgn-divider orgn-bg-white my-4" animateOnScroll animationName="visible"></span>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-6">
          <p class="hide-on-init text-center" animateOnScroll animationName="animated fadeIn">
            Office 3B | 73 Juta Street
            <br/>
            Braamfontein | Johannesburg | South Africa
            <br />
            +27 11 492 0197 | hello@originators.tv
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="orgn-bg-dark p-3 p-md-5 w100 text-white  align-items-center justify-content-center d-flex">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-md-6 py-2">
        <h3 class="sub-heading">
          New Business Enquiries
        </h3>
        <p>
          Work with us: <a href="mailto:production@originators.tv" class="link">production@originators.tv</a>
        </p>
      </div>
      <div class="col-12 col-md-6 py-2">
        <h3 class="sub-heading">
          Careers
        </h3>
        <p>
          We are always looking for talent.<br>
          Please send us your CV and a Portfolio: <a href="mailto:hr@originators.tv" class="link">hr@originators.tv</a>
        </p>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12 col-md-6 py-2">
        <h3 class="sub-heading">
          Marketing &amp; PR Enquiries
        </h3>
        <p>
          For more information on Originators TV, Press and Media Enquiries: <a href="mailto:pr@originators.tv" class="link">pr@originators.tv</a>
        </p>
      </div>
    </div>
  </div>
</section>
<section class="orgn-half orgn-hm-landing-3">
  <div class="orgn-half w100 align-items-center d-flex justify-content-center">
    <div class="row align-items-center">
      <div class="col-12 col-md-4 py-5 py-md-0 text-white ">
        <h1 class="text-center hide-on-init" animateOnScroll animationName="animated fadeInDown">General Enquiries</h1>
        <span class="orgn-divider orgn-bg-white my-4" animateOnScroll animationName="visible"></span>
        <p class="p-3 text-center">We look forward to helping you with your inquiry. We respond to email messages in
          the order that they are received, and will respond to your email as quickly as possible.</p>

      </div>
      <div class="col-12 col-md-8 orgn-half d-flex orgn-bg-white align-items-center">
        <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm" novalidate class="w100 p-5">
          <div class="form-group">
            <label for="exampleInputEmail1">Your name <span class="required"><sup>*</sup></span></label>
            <input type="text" class="form-control" [(ngModel)]="data.name" name="name" required placeholder="Name">
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Your e-mail address <span class="required"><sup>*</sup></span></label>
            <input type="email" class="form-control" id="email" [(ngModel)]="data.email" required name="email" placeholder="E-mail">
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Subject <span class="required"><sup>*</sup></span></label>
            <input type="text" class="form-control" id="subject" [(ngModel)]="data.subject" required name="subject" placeholder="Subject">
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Message <span class="required"><sup>*</sup></span></label>
            <textarea class="form-control" [(ngModel)]="data.message" id="message" name="message" required placeholder="Message"></textarea>
          </div>

          <button type="submit" class="btn btn-default">Submit</button>
        </form>
      </div>
    </div>
  </div>
</section>
