<section class="first-section orgn-half container">
  <div class="p-5" *ngIf="!!selectedProduct.video">
    <div class="videoWrapper">
      <iframe width="560" height="349" *ngIf="current_url" [src]="current_url" frameborder="0" allowfullscreen></iframe>
    </div>
    <div class="row">
      <div class="col-md-6 text-center centered centered-dark" (click)="scrollToContent()">
        <div class="arrow">
          <ul class="list-unstyled">
            <li class="p">
              <span class="icon-chevron-down"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="orgn-half hide-on-init orgn-bg-l-dark" animateOnScroll animationName="animated fadeIn" id="org-content">
  <div class="container p-5">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <h1 class="text-center hide-on-init" animateOnScroll animationName="animated fadeInDown">
          {{selectedProduct.title}}
        </h1>
        <span class="orgn-divider orgn-bg-white my-4" animateOnScroll animationName="visible"></span>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 col-lg-12">
        <div class="hide-on-init text-center" animateOnScroll animationName="animated fadeIn"
          [innerHTML]="selectedProduct.description">
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6 pt-5">
        <div class="videoWrapper">
          <iframe width="640" height="360" src="https://www.youtube.com/embed/7uDZyDTFP9Y?rel=0" frameborder="0"
            allowfullscreen></iframe></div>
      </div>
      <div class="col-sm-12 col-md-6 pt-5">
        <div class="videoWrapper">
          <iframe width="640" height="360" src="https://www.youtube.com/embed/A8TsrzXmCiU?rel=0" frameborder="0"
            allowfullscreen></iframe></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-sm-12 col-md-6 pt-5 pb-5">
        <div class="videoWrapper">
          <iframe width="640" height="360" src="https://www.youtube.com/embed/fvUtO6iHBMo?rel=0" frameborder="0"
            allowfullscreen></iframe></div>
      </div>
    </div>
  </div>
</section>
