<section class="first-section orgn-half "
  [ngStyle]="{'background-image': 'url(' + selectedProduct.banner + ')', 'background-size': 'cover', 'background-position': 'center top'}">
  <div class="p-3 p-md-5 w100 align-items-center justify-content-center d-flex">
  </div>
</section>
<section class="orgn-half orgn-hm-landing">
  <div class="orgn-half p-3 p-md-5 w100 orgn-bg-dark text-white  align-items-center justify-content-center d-flex">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <h1 class="text-center hide-on-init" animateOnScroll animationName="animated fadeInDown">{{selectedProduct.title}}
          </h1>
          <span class="orgn-divider orgn-bg-white my-4" animateOnScroll animationName="visible"></span>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-6 col-lg-12">
          <div class="hide-on-init text-center" animateOnScroll animationName="animated fadeIn" [innerHTML]="selectedProduct.description">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 text-center centered" (click)="scrollToContent()">
          <div class="arrow">
            <ul class="list-unstyled">
              <li class="p">
                <span class="icon-chevron-down"></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="orgn-half" id="org-content">
  <div class="p-5" *ngIf="!!selectedProduct.video">
    <div *ngIf="type==='hosted'">
      <video id="video_main"
        class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9" controls
        [poster]="selectedProduct.image" preload="auto" width="640" height="264">
        <source [src]="current_url" type="video/mp4" />
      </video>
    </div>
    <div class="videoWrapper" *ngIf="type!=='hosted'">
      <iframe width="560" height="349" *ngIf="current_url" [src]="current_url" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
  <div class="container p-5" *ngIf="!selectedProduct.video">
    <div class="row justify-content-center">
      <div class="col-12 text-center p-3" *ngIf="!hasOtherImages()">
        <img [src]="selectedProduct.banner" alt="selectedProduct.title" class="img-fluid">
      </div>
      <div class="col-12 text-center" *ngIf="hasOtherImages()">
        <img [src]="campaign_url" alt="selectedProduct.title" class="img-fluid p-3"
          *ngFor="let campaign_url of selectedProduct.img_campaigns;" />
      </div>
    </div>
  </div>
</section>
<section class="orgn-half orgn-bg-l-dark" *ngIf="hasOtherVideos()">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 text-center p-5" *ngFor="let campaign_url of video_urls;">
        <div class="videoWrapper">
          <iframe width="560" height="349" *ngIf="campaign_url" [src]="campaign_url" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</section>
