import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../shared/product';
import { SafeUrl } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';

import videojs from 'video.js';

@Component({
  selector: 'app-template-default',
  templateUrl: './template-default.component.html',
  styleUrls: ['./template-default.component.scss']
})
export class TemplateDefaultComponent implements OnInit {

  @Input() selectedProduct: Product;
  @Input() current_url: SafeUrl;
  @Input() video_urls: Array<SafeUrl>;
  @Input() type: String;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // ID with which to access the template's video element
    if(this.type === 'hosted'){
      this.initialVideoJS();
    }
  }

  scrollToContent(): void {
    const el = document.getElementById('org-content');
    window.scroll({top: el.offsetTop, left: 0, behavior: 'smooth'});
  }

  hasOtherImages(): Boolean {
    if(Boolean(this.selectedProduct.img_campaigns)) {
      return (this.selectedProduct.img_campaigns.length > 0);
    }
    return false;
  }

  hasOtherVideos(): Boolean {
    if(Boolean(this.selectedProduct.video_campaigns)) {
      return (this.selectedProduct.video_campaigns.length > 0);
    }
    return false;
  }

  initialVideoJS(): void {
    const el = 'video_main';
    const els = document.getElementsByClassName('video-js');
    for (let i = 0; i < els.length; i++) {
      videojs(els[i], {}, function () {

        // Store the video object
        const myPlayer = this, id = myPlayer.id();
        // Make up an aspect ratio
        const aspectRatio = 264 / 640;
        // internal method to handle a window resize event to adjust the video player
        function resizeVideoJS() {
          const videoId = document.getElementById(id);
          if (videoId) {
            const width = videoId.parentElement.offsetWidth;
            if (!!myPlayer) {
              myPlayer.width(width);
              myPlayer.height(width * aspectRatio);
            }
          }
        }
        // Initialize resizeVideoJS()
        resizeVideoJS();
        // Then on resize call resizeVideoJS()
        // window.onresize = resizeVideoJS;
      });
    }
  }
}
