<footer class="footer p-3">
    <div class="container-fluid">
        <div class="row justify-content-end align-items-center">
            <div class="col-12 col-md-4 text-sm-center text-md-left">
              <span>&copy; Copyright 2020 Originators TV. All rights reserved.</span>
            </div>
            <div class="col-12 col-md-4 text-center p-4">
                <a routerLink="policy">Privacy Policy</a>
                |
                <a routerLink="terms">Terms &amp; Conditions</a>
            </div>
            <div class="col-12 col-md-4 orgn-footer-copy text-sm-center text-md-right">
                <span>Office 3B | 73 Juta Street<br/>Braamfontein | Johannesburg | South Africa</span>
                <span class="d-block">+27 10 206 9071 | <a href="mailto:hello@originators.tv">hello@originators.tv</a></span>
            </div>
        </div>
    </div>
</footer>