import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { WorkComponent } from './work/work.component';
import { DetailComponent } from './detail/detail.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { InternComponent } from './intern/intern.component';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import { OriginateForGoodComponent } from './originate-for-good/originate-for-good.component';
import { GdohComponent } from './gdoh/gdoh.component';

const routes: Routes = [
  { path: '', redirectTo: '/about', pathMatch: 'full' },
  { path: 'theformula', redirectTo: '/detail/112', pathMatch: 'full' },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'detail/:id', component: DetailComponent },
  { path: 'work', component: WorkComponent },
  { path: 'thanks', component: ThankYouComponent },
  { path: 'intern', component: InternComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'policy', component: PolicyComponent },
  { path: 'forgood', component: OriginateForGoodComponent },
  { path: 'gdoh', component: GdohComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }