<section class="first-section">
    <div class="p-3 p-md-5 w100 orgn-bg-dark text-white  align-items-center justify-content-center d-flex">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <h1 class="text-center">Terms and Conditions</h1>
            <span class="orgn-divider orgn-bg-white my-5" animateOnScroll animationName="visible"></span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container pt-5 pb-5">
      <div class="row">
        <div class="col-12">
          <h3>Use Of The Site</h3>
          <p>
              Access to and use of this site are governed by these terms and conditions, as well as applicable laws, statutes, ordinances and regulations. By accessing this site, you agree to abide by the terms and conditions set forth below and acknowledge that any other agreements between you and us are superseded and of no force or effect.
          </p>
          <p>
              We reserve the right to change these terms and conditions at any time. You are responsible for regularly reviewing these terms and conditions so that you will be apprised of any changes. Your use of the site following any modification in these terms and conditions will signify your assent to and acceptance of the modified terms and conditions. These terms and conditions are effective as of 1 March, 2015.
          </p>
          <h3>Copyright And Trademark Notice</h3>
          <p>
              This site is protected by The Republic of South Africa's copyright laws, and may be protected by the copyright laws of other countries and jurisdictions. Except as specifically permitted in these terms and conditions, any copy, reproduction, display, performance or retransmission of the contents of this site is strictly prohibited. Any use of any trademarks, trade names and logos in which we, our subsidiaries, affiliates, divisions and other companies identified on our web site do business is strictly prohibited absent our prior written consent. In addition, the names, trade names and trademarks of third parties, including our clients, that appear on this site are proprietary to their respective owners and may be used only with their express written permission. We neither warrant nor represent that your use of materials displayed on the site will not infringe upon the rights of third parties.
          </p>
          <h3>
              Unsolicited Materials
          </h3>
          <p>
              Please note that it is our policy not to accept or consider unsolicited creative, production-related or other materials of any kind. We appreciate your cooperation in adhering to this policy.
          </p>
          <h3>Disclaimers And Limitation Of Liability</h3>
          <p>
              Your use and browsing of the site is at your own risk. We make no representations or warranties of any kind as to:
          </p>
          <p>
            (a) the accuracy or completeness of the information or materials on the site and assume no responsibility for any errors or omissions in its content;
            <br/>(b) the availability for use of any copyrighted, trademarked or proprietary materials of third parties that may appear in this site;
            <br/>(c) computer viruses or other bugs that third parties may embed in or attach to this site without our knowledge or consent;
            <br/>(d) any software made available for downloading, copying or other use through this site; or
            <br/>(e) the merchantability, fitness for use, title and/or non-infringement of any or all of the contents of this site. Neither we nor anyone involved in the creation or delivery of this site or whose materials or information appear on this site shall have any liability (whether based on contract, tort, statute or otherwise) for any costs, losses, damages (whether direct, indirect, compensatory, special, lost profits, liquidated, consequential or punitive), arising out of or in connection with your access to, browsing of or use of this site or any of the contents of this site.
          </p>
          <h3>Use Of Site Materials</h3>
          <p>All of our (an our clients) proprietary documents, images, designs and information, including information about our businesses, biographical information of our officers and other public information posted about our company on this site may not be copied, reproduced, downloaded and used without our written permission. If you would like to use, copy, upload or retransmit any of our proprietary materials, call or e-mail us to request written permission.</p>
          <p>The contents of this site may contain documents, images, information and other materials not proprietary to us, such as photographs, clip art, film clips, musical passages, audio track or sound effects elements, or the names, trade names, trademarks, logos or designations of third parties. Any use whatsoever of any of these third party materials is strictly prohibited, unless the prior written permission of the appropriate third parties has been secured. </p>
        </div>
      </div>
    </div>
  </section>