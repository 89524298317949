<section class="first-section orgn-half container" *ngIf="!!selectedProduct.video_campaigns.video">
  <div class="p-5">
    <video id="video_main" *ngIf="main_video.type==='hosted'"
      class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9" controls
      [poster]="main_video.image" preload="auto" width="640" height="264">
      <source [src]="main_video.url" type="video/mp4" />
    </video>
    <div class="videoWrapper" *ngIf="main_video.type==='stream'">
      <iframe width="560" height="349" [src]="main_video.url" frameborder="0" allowfullscreen></iframe>
    </div>
    <div class="row">
      <div class="col-md-6 text-center centered centered-dark" (click)="scrollToContent()">
        <div class="arrow">
          <ul class="list-unstyled">
            <li class="p">
              <span class="icon-chevron-down"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="orgn-half hide-on-init orgn-bg-l-dark" [ngClass]="{'first-section':!selectedProduct.video_campaigns.video}" animateOnScroll animationName="animated fadeIn" id="org-content">
  <div class="container p-5">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <h1 class="text-center hide-on-init" animateOnScroll animationName="animated fadeInDown">
          {{selectedProduct.title}}
        </h1>
        <span class="orgn-divider orgn-bg-white my-4" animateOnScroll animationName="visible"></span>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 col-lg-12">
        <div class="hide-on-init text-center" animateOnScroll animationName="animated fadeIn"
          [innerHTML]="selectedProduct.description">
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6 pt-5 pb-5" *ngFor="let item of selectedProduct.video_campaigns.content;">
        <img *ngIf="item.image" [src]="item.image" class="img-fluid">
        <div *ngIf="!!item.video" class="videoWrapper pt-0">
          <iframe *ngIf="item.video.type==='stream'" width="640" height="360" [src]="item.video.url" frameborder="0"
            allowfullscreen></iframe>
          <video *ngIf="item.video.type==='hosted'" [id]="item.video.url"
            class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9" controls
            [poster]="item.video.image" preload="auto" width="640" height="264">
            <source [src]="item.video.url" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </div>
</section>
