<section class="first-section orgn-half orgn-hm-landing">
  <div class="orgn-half p-3 p-md-5 w100 orgn-bg-dark text-white  align-items-center justify-content-center d-flex">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <h1 class="text-center hide-on-init" animateOnScroll animationName="animated fadeInDown">A short-form creative content agency, built for now and next.</h1>
          <span class="orgn-divider orgn-bg-white my-4" animateOnScroll animationName="visible"></span>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-6">
          <h5 class="hide-on-init text-center" animateOnScroll animationName="animated fadeIn">Leveraging the power of word, sound and key visuals, we create gripping short-form content.</h5>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="orgn-dark">
  <div class="container-fluid no-gutters p-0">
    <div class="row justify-content-center no-gutters">
      <div class="col-6 col-md-4 image-hover-text-container hide-on-init" animateOnScroll animationName="animated fadeInUp"
        *ngFor="let product of products;"  (click)="viewProduct(product.id)">
        <div class="image-hover-image">
          <img src="{{product.image}}" class="img-fluid">
        </div>
        <div class="image-hover-text">
          <div class="image-hover-text-bg">
          </div>
          <div class="image-hover-text-bubble p-1">
            <span class="image-hover-text-title">{{product.title}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 text-center py-4">
        <button type="button" class="btn btn-outline-light" (click)="viewWork()" offset="120" animateOnScroll animationName="animated fadeInUp">view
          all</button>
      </div>
    </div>
  </div>
</section>
<section class="orgn-half align-items-center justify-content-center d-flex">
  <div class="p-3">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <h1 class="text-center hide-on-init" animateOnScroll animationName="animated fadeInDown">We practice the
            philosophy of Origination</h1>
          <span class="orgn-divider my-3" animateOnScroll animationName="visible"></span>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <p class="def text-center">
              <span class="orgn-txt-r"><b>Origination</b></span> &nbsp;<em>-verb-</em> &nbsp;the superior art
              and skill of
              systematic conceptualisation, ideation and execution.
              <br/><em class="orgn-txt-r"><b>The elevation of
                  exceptional brands.</b></em>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="orgn-half orgn-hm-landing-2">
  <div class="orgn-half p-3 p-md-5 text-white w100 align-items-center d-flex justify-content-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <h3 class="text-center hide-on-init" animateOnScroll animationName="animated fadeInUp">
            <p>We give brands the edge to thrive, work with us.</p>
            <p>Our deep understanding of the African Mainstream audience helps brands capture the hearts and minds of their customers.</p>
            <p>Our currency is the ability to create content that can grab peoples attention in under 30 seconds.
            <br/>We are in tune with what is current.</p>
          </h3>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="orgn-half orgn-hm-landing-3">
  <div class="orgn-half text-white w100 align-items-center d-flex justify-content-center">
    <div class="row align-items-center">
      <div class="col-12 col-md-4 py-5 py-md-0">
        <h1 class="text-center hide-on-init" animateOnScroll animationName="animated fadeInDown">Clients</h1>
        <span class="orgn-divider orgn-bg-white my-4" animateOnScroll animationName="visible"></span>
        <p class="p-3 text-center">The common thread in what we do is woven in our unwavering focus on building our
          clients' brands and offering a measurable return on their investment in our ability to positively influence a
          multitude of consumers through our work. </p>
      </div>
      <div class="col-12 col-md-8 orgn-half d-flex orgn-bg-white align-items-center">
        <div class="row p-3 p-md-5">
          <div class="col-4 col-md-2" *ngFor="let client of clients; index as i" [ngClass]="{'col-md-4': client.name === 'Gauteng Department of Health'}">
            <a href="{{client.link}}" target="_blank"><img src="{{client.img}}" class="client-logo" [ngClass]="{'client-logo-2x': client.name === 'Gauteng Department of Health'}"/></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
