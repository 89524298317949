import { Component, OnInit, Input } from '@angular/core';
import { Product, ProductVideo } from '../shared/product';
import { SafeUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from '../shared/product.service';

import videojs from 'video.js';

@Component({
  selector: 'app-template-content',
  templateUrl: './template-content.component.html',
  styleUrls: ['./template-content.component.scss']
})
export class TemplateContentComponent implements OnInit {

  constructor(private modalService: NgbModal, private productService: ProductService) { }

  @Input() selectedProduct: Product;
  @Input() current_url: SafeUrl;
  @Input() video_urls: Array<SafeUrl>;

  main_video: ProductVideo;

  ngOnInit() {
    this.main_video = this.selectedProduct.video_campaigns.video;
    if (this.main_video && this.main_video.type === 'stream') {
      this.main_video.url = this.productService.cleanURL(this.main_video.url);
    }
    const totalItems = this.selectedProduct.video_campaigns.content.length;
    for (let i = 0; i < totalItems; i++) {
      const item = this.selectedProduct.video_campaigns.content[i];
      if (item.image) { continue; }
      if (item.video.type === 'stream') {
        this.selectedProduct.video_campaigns.content[i].video.url = this.productService.cleanURL(item.video.url);
      }
    }
  }

  ngAfterViewInit() {
    // ID with which to access the template's video element
    const el = 'video_main';
    const els = document.getElementsByClassName('video-js');
    for (let i = 0; i < els.length; i++) {
      videojs(els[i], {}, function () {

        // Store the video object
        const myPlayer = this, id = myPlayer.id();
        // Make up an aspect ratio
        const aspectRatio = 264 / 640;
        // internal method to handle a window resize event to adjust the video player
        function resizeVideoJS() {
          const width = document.getElementById(id).parentElement.offsetWidth;
          if (!!myPlayer) {
            myPlayer.width(width);
            myPlayer.height(width * aspectRatio);
          }
        }
        // Initialize resizeVideoJS()
        resizeVideoJS();
        // Then on resize call resizeVideoJS()
        // window.onresize = resizeVideoJS;
      });
    }
  }

  scrollToContent(): void {
    const el = document.getElementById('org-content');
    window.scroll({ top: el.offsetTop, left: 0, behavior: 'smooth' });
  }

  hasOtherImages(): Boolean {
    if (Boolean(this.selectedProduct.img_campaigns)) {
      return (this.selectedProduct.img_campaigns.length > 0);
    }
    return false;
  }

  hasOtherVideos(): Boolean {
    if (Boolean(this.selectedProduct.video_campaigns)) {
      return (this.selectedProduct.video_campaigns.length > 0);
    }
    return false;
  }

  openModal(content): void {
    this.modalService.open(content, { size: 'lg' });
  }

  cleanUrl(url): SafeUrl {
    return this.productService.cleanURL(url);
  }
  trackByFn(index, item) {
    return index; // or item.id
  }
}
