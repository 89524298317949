<section class="first-section container">
  <div class="row">
    <div class="col-12 py-2">
      <img src="assets/img/clients/health.jpg" class="img-fluid"/>
      <h2 class="hide-on-init" animateOnScroll animationName="animated fadeInDown">Originate for Impact</h2>
      <p class="hide-on-init py-4" animateOnScroll animationName="animated fadeInUp">
        We have the honour to work with the Gauteng Department of Health in creating content that educates the public about COVID-19. We use our skills to Originate for Impact. Below is some of the content we have created for Gauteng Department of Health.
      </p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-12 col-md-8 pb-5">
      <video id="video_main" class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9" controls
        poster="assets/video/health_image.jpg" preload="auto" width="640" height="264">
        <source src="assets/video/health_1.mp4" type="video/mp4" />
      </video>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-12 col-md-6 pb-5">
      <video id="video_main" class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9" controls
        poster="assets/video/health_2.jpg" preload="auto" width="640" height="264">
        <source src="assets/video/health_2.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="col-12 col-md-6 pb-5">
      <video id="video_main" class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9" controls
        poster="assets/video/health_3.jpg" preload="auto" width="640" height="264">
        <source src="assets/video/health_3.mp4" type="video/mp4" />
      </video>
    </div>
  </div>
  <div class="row justify-content-center pb-5">
    <div *ngFor="let image of _album; let i=index" class="col-xs-6 col-sm-3 col-md-4 px-2 my-2">
      <img [src]="image.thumb" class="img-fluid gallery-img" (click)="open(i)"/>
    </div>
  </div>
</section>