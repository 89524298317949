import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../shared/product';
import { SafeUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import videojs from 'video.js';

@Component({
  selector: 'app-template-content-basic',
  templateUrl: './template-content-basic.component.html',
  styleUrls: ['./template-content-basic.component.scss']
})
export class TemplateContentBasicComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  @Input() selectedProduct: Product;
  @Input() current_url: SafeUrl;
  @Input() video_urls: Array<SafeUrl>;

  ngOnInit() {
  }

  ngAfterViewInit() {
    // ID with which to access the template's video element
    const el = 'video_main';
    const els = document.getElementsByClassName('video-js');
    for (let i = 0; i < els.length; i++) {
      videojs(els[i], {}, function () {

        // Store the video object
        var myPlayer = this, id = myPlayer.id();
        // Make up an aspect ratio
        var aspectRatio = 264 / 640;
        // internal method to handle a window resize event to adjust the video player
        function resizeVideoJS() {
          var width = document.getElementById(id).parentElement.offsetWidth;
          myPlayer.width(width).height(width * aspectRatio);
        }
        // Initialize resizeVideoJS()
        resizeVideoJS();
        // Then on resize call resizeVideoJS()
        window.onresize = resizeVideoJS;
      });
    }
  }

  scrollToContent(): void {
    const el = document.getElementById('org-content');
    window.scroll({ top: el.offsetTop, left: 0, behavior: 'smooth' });
  }

  hasOtherImages(): Boolean {
    if (Boolean(this.selectedProduct.img_campaigns)) {
      return (this.selectedProduct.img_campaigns.length > 0);
    }
    return false;
  }

  hasOtherVideos(): Boolean {
    if (Boolean(this.selectedProduct.video_campaigns)) {
      return (this.selectedProduct.video_campaigns.length > 0);
    }
    return false;
  }

  openModal(content): void {
    this.modalService.open(content, { size: 'lg' });
  }
}
