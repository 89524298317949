<ng-template #content let-modal>
  <div class="modal-body">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <video id="video_main" class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9" controls
      poster="assets/img/2019_10_26_show_reel.png" preload="auto" width="640" height="264">
      <source src="assets/video/OG_Reel_All_Work.mp4" type="video/mp4" />
    </video>
  </div>
</ng-template>
<section class="first-section">
  <div class="p-3 p-md-5 w100 orgn-bg-dark text-white  align-items-center justify-content-center d-flex">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 text-center">
          <h1 class="hide-on-init" animateOnScroll animationName="animated fadeInDown">Our work manifests
            in many forms, from our speciality in TV spots to brand development and the on-air marketing of special
            events.</h1>
          <span class="orgn-divider orgn-bg-white my-5" animateOnScroll animationName="visible"></span>
          <button type="button" class="btn btn-outline-light" (click)="openModal(content)" offset="120" animateOnScroll
            animationName="animated fadeInUp">view
            our reel</button>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="orgn-half orgn-dark py-3">
  <div class="container-fluid no-gutters p-0">
    <ngb-tabset type="pills" justify="center">
      <ngb-tab title="All">
        <ng-template ngbTabContent>
          <div class="row justify-content-center no-gutters">
            <div class="col-6 col-md-4 image-hover-text-container hide-on-init" *ngFor="let product of products;"
              animateOnScroll animationName="animated fadeInUp" (click)="viewProduct(product.id)">
              <div class="image-hover-image">
                <img src="{{product.image}}" class="img-fluid">
              </div>
              <div class="image-hover-text">
                <div class="image-hover-text-bg">
                </div>
                <div class="image-hover-text-bubble p-1">
                  <span class="image-hover-text-title">{{product.title}}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="Broadcast Branding">
        <ng-template ngbTabContent>
          <div class="row justify-content-center no-gutters">
            <div class="col-6 col-md-4 image-hover-text-container hide-on-init" *ngFor="let product of productsShow;"
              animateOnScroll animationName="animated fadeInUp" (click)="viewProduct(product.id)">
              <div class="image-hover-image">
                <img src="{{product.image}}" class="img-fluid">
              </div>
              <div class="image-hover-text">
                <div class="image-hover-text-bg">
                </div>
                <div class="image-hover-text-bubble p-1">
                  <span class="image-hover-text-title">{{product.title}}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="Entertainment Marketing">
        <ng-template ngbTabContent>
          <div class="row justify-content-center no-gutters">
            <div class="col-6 col-md-4 image-hover-text-container hide-on-init" (click)="viewProduct(product.id)"
              *ngFor="let product of productsEvent;" animateOnScroll animationName="animated fadeInUp">
              <div class="image-hover-image">
                <img src="{{product.image}}" class="img-fluid">
              </div>
              <div class="image-hover-text">
                <div class="image-hover-text-bg">
                </div>
                <div class="image-hover-text-bubble p-1">
                  <span class="image-hover-text-title">{{product.title}}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="Content">
        <ng-template ngbTabContent>
          <div class="row justify-content-center no-gutters">
            <div class="col-6 col-md-4 image-hover-text-container hide-on-init" *ngFor="let product of productsContent;"
              animateOnScroll animationName="animated fadeInUp" (click)="viewProduct(product.id)">
              <div class="image-hover-image">
                <img src="{{product.image}}" class="img-fluid">
              </div>
              <div class="image-hover-text">
                <div class="image-hover-text-bg">
                </div>
                <div class="image-hover-text-bubble p-1">
                  <span class="image-hover-text-title">{{product.title}}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="Digital Campaigns">
        <ng-template ngbTabContent>
          <div class="row justify-content-center no-gutters">
            <div class="col-6 col-md-4 image-hover-text-container hide-on-init" *ngFor="let product of productsAdvert;"
              animateOnScroll animationName="animated fadeInUp" (click)="viewProduct(product.id)">
              <div class="image-hover-image">
                <img src="{{product.image}}" class="img-fluid">
              </div>
              <div class="image-hover-text">
                <div class="image-hover-text-bg">
                </div>
                <div class="image-hover-text-bubble p-1">
                  <span class="image-hover-text-title">{{product.title}}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</section>
