import { Product } from './product';
/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

export const PRODUCTS: Product[] = [
    {
        client: 'Vuzu Amp',
        title: 'Sneaker Exchange Nov 2017',
        image: 'assets/thumbs/Vuzu-Amp-Sneaker-Exchange-Nov-2017_tn.jpg',
        banner: 'assets/img/Vuzu-Amp-Sneaker-Exchange-Nov-2017.jpg',
        description: 'A party is always more than just the date. It\'s the people, the place, the performances and the vibe that makes it all come together. It\'s an event! We drive ticket sales and attendance with this TV spot.',
        video: 'https://www.youtube.com/embed/E_LW84mYV_k',
        category: 'event',
        date: '2017-10-09',

    }, {
        client: 'Mzansi Magic Music',
        title: 'Fact Durban Rocks Event',
        image: 'assets/thumbs/fact_durban_tn.jpg',
        banner: 'assets/img/fact_durban.jpg',
        description: 'Ain\'t no party like a Fact party! A TV spot for Channel O\'s Fact Durban Rocks Durban July edition event.',
        video: 'https://www.youtube.com/embed/i2y10KGtd5c',
        category: 'event',
        date: '2016-07-04',

    }, {
        client: 'BET',
        title: 'Undercover Mid Season Refresh',
        image: 'assets/thumbs/undercover_tn.jpg',
        banner: 'assets/img/undercover.jpg',
        description: 'A successful barrister, is set to become the first black Director of Public Prosecutions. A TV spot for BET\'s mid season refresh of the gripping series, Undercover.',
        video: 'https://www.youtube.com/embed/C-iUJiK2W1k',
        category: 'show',
        date: '2016-07-04',

    }, {
        client: 'Kwesé',
        title: 'Kwesé Exclusive Channels Advert ',
        image: 'assets/thumbs/Kwese-Exclusive-Channels-Advert_tn.jpg',
        banner: 'assets/img/Kwese-Exclusive-Channels-Advert.jpg',
        description: '',
        video: 'https://www.youtube.com/embed/0lHNZthncoI',
        category: 'show',
        date: '2017-04-12',

    }, {
        client: 'Channel O',
        title: 'Castle Lite Unlocks Concert',
        image: 'assets/thumbs/Channel-O-Castle-Lite-Unlocks-Concert_tn.jpg',
        banner: 'assets/img/Channel-O-Castle-Lite-Unlocks-Concert.jpg',
        description: 'Unlock the unexpected in one city with one international star. A TV spot for Channel O’s Castle Lite Unlocks Chance the Rapper event.',
        video: 'https://www.youtube.com/embed/ACF5FuSjx7o',
        category: 'event',
        date: '2017-04-25',

    }, {
        client: 'Crimson Multimedia Kenya',
        title: 'Black Mass TV Spot',
        image: 'assets/thumbs/black_mass_tn.jpg',
        banner: 'assets/img/black_mass.jpg',
        description: 'Brad Pit makes an alliance with the fed in the blockbuster movie, Black Mass! A TV Spot for the cinema debut of Black Mass in East & West Africa.',
        video: 'https://www.youtube.com/embed/Fl5EYWQBfpE',
        category: 'show',
        date: '2016-06-10',

    }, {
        client: 'BET',
        title: 'Black Panthers',
        image: 'assets/thumbs/black_panthers_tn.jpg',
        banner: 'assets/img/black_panthers.jpg',
        description: 'You can jail a revolutionary but you can\'t jail a revolution! A BET special premier of The Black Panthers - Vanguard of the revolution.',
        video: 'https://www.youtube.com/embed/mGAA_FCvsM0',
        category: 'show',
        date: '2016-07-04',

    }, {
        client: 'DSTV',
        title: 'Marula Festival',
        image: 'assets/thumbs/DSTV-Marula-Festival_tn.jpg',
        banner: 'assets/img/DSTV-Marula-Festival.jpg',
        description: 'The festival of 1<sup>st</sup> fruit with the finest jazz and gospel music. A TV Spot for DSTV’s Annual Limpopo Marula Fest event.',
        video: 'https://www.youtube.com/embed/iRSO1bDIOUs',
        category: 'event',
        date: '2017-04-12',

    }, {
        client: 'Fox Life',
        title: 'Africa The Best Theme',
        image: 'assets/thumbs/Fox-Life-Africa-The-Best-Theme_tn.jpg',
        banner: 'assets/img/Fox-Life-Africa-The-Best-Theme.jpg',
        description: 'Its lights, camera and action with these larger than life stars! A Fox Life Africa Theme Piece showcasing the best of FOX entertainment.',
        video: 'https://www.youtube.com/embed/IN0YyKA3SZc',
        category: 'show',
        date: '2017-04-12',

    }, {
        client: 'Mzansi Magic Music',
        title: 'Back to the Old Skool 2017:',
        image: 'assets/thumbs/Mzansi-magic-Music-Back-to-the-Old-Skool-2017_tn.jpg',
        banner: 'assets/img/Mzansi-magic-Music-Back-to-the-Old-Skool-2017.jpg',
        description: 'A fully loaded global celebration of old school house music from the golden era. A TV spot for Mzansi Magic Music\'s Back to the Old Skool event.',
        video: 'https://www.youtube.com/embed/yKGTvl0vrsU',
        category: 'event',
        date: '2017-04-25',

    }, {
        client: 'Kwesé',
        title: 'Kwesé Premium Entertainment Advert',
        image: 'assets/thumbs/Kwese-Premium-Entertainment-Advert_tn.jpg',
        banner: 'assets/img/Kwese-Premium-Entertainment-Advert.jpg',
        description: 'Raise it up to exclusive entertainment, hit movies, premier series, faith, documentaries, news and much more, all at your fingertips. Kwese, Beyond TV!',
        video: 'https://www.youtube.com/embed/vqGi1FWSr80',
        category: 'show',
        date: '2017-04-12',

    }, {
        client: 'BET',
        title: 'Top Actor Africa Voting',
        image: 'assets/thumbs/top_actor_tn.jpg',
        banner: 'assets/img/top_actor.jpg',
        description: 'Africa rise up and let your voice be heard! A voting spot for the next Top Actor Africa.',
        video: 'https://www.youtube.com/embed/EaM5vLtpQkU',
        category: 'show',
        date: '2016-07-04',

    }, {
        client: 'Mzansi Magic Music',
        title: 'SA Music Carnival',
        image: 'assets/thumbs/music_carnival_tn.jpg',
        banner: 'assets/img/music_carnival.jpg',
        description: 'The best of festivities in a unique SA music carnival experience. A TV Spot for Mzansi Magic Music’s SA Music Carnival event.',
        video: 'https://www.youtube.com/embed/2_-fB2H6NH8',
        category: 'event',
        date: '2016-07-04',

    }, {
        client: 'Channel O',
        title: 'Top 5',
        image: 'assets/thumbs/top_5_tn.jpg',
        banner: 'assets/img/top_5.jpg',
        description: 'From trap queens to the classic man, Channel O throws it back with a count down of the hottest tracks of 2015. A TV spot for Channel O\'s Top 50.',
        video: 'https://www.youtube.com/embed/U8whqNceOZs',
        category: 'show',
        date: '2016-07-04',

    }, {
        client: 'Channel O',
        title: 'Colour in Ekurhuleni',
        image: 'assets/thumbs/colour_ekhuruleni_tn.jpg',
        banner: 'assets/img/colour_ekhuruleni.jpg',
        description: 'Splashing color and smashing sounds that will get you turnt in full color. A TV Spot for Channel O’s Color in Ekhuruleni event.',
        video: 'https://www.youtube.com/embed/KSuopJ419kw',
        category: 'event',
        date: '2016-07-04',

    }, {
        client: 'Channel O',
        title: 'The Blackout',
        image: 'assets/thumbs/black_out_tn.jpg',
        banner: 'assets/img/black_out.jpg',
        description: 'Show up and get extra lit with SA’s best artists on lock! A TV Spot for Channel O’s Blackout event.',
        video: 'https://www.youtube.com/embed/bfd75_OHt08',
        category: 'event',
        date: '2016-07-04',

    }, {
        client: 'Channel O',
        title: 'Head Honcho 7th Birthday event',
        image: 'assets/thumbs/head-honcho_tn.jpg',
        banner: 'assets/img/head-honcho.jpg',
        description: 'The year is never over without the Head Honcho Birthday Bash where we party like crazy! A TV spot for the Head Honcho Birthday Bash event in association with Channel O.',
        video: 'https://www.youtube.com/embed/fZuszDdMeZg',
        category: 'event',
        date: '2016-06-10',

    }, {
        client: 'Channel O',
        title: 'I Believe',
        image: 'assets/thumbs/oskido_tn.jpg',
        banner: 'assets/img/oskido.jpg',
        description: '"Party people in the place to be, put up your hands and scream!" A TV Spot for Channel O\'s I believe Experience event.',
        video: 'https://www.youtube.com/embed/sUtdDDPRhiU',
        category: 'event',
        date: '2016-06-10',

    }, {
        client: 'Channel O',
        title: 'Raise the Roof',
        image: 'assets/thumbs/raise-the-roof_tn.jpg',
        banner: 'assets/img/raise-the-roof.jpg',
        description: 'Raise it up with 5 Stages and 50 Acts. A TV Spot for Channel O\'s Raise the Roof event.',
        video: 'https://www.youtube.com/embed/WIiHTrjONas',
        category: 'event',
        date: '2016-06-10',

    }, {
        client: 'Channel O',
        title: 'Maftown Heights TKZee Announcement',
        image: 'assets/thumbs/tkzee-announcement_tn.jpg',
        banner: 'assets/img/tkzee-announcement.jpg',
        description: 'Witness greatness as Channel O brings you the most epic moment at the Maftown Heights. A Channel O announcement of a special performance by legendary TKZee at the Maftown Heights 2015 concert.',
        video: 'https://www.youtube.com/embed/bn-f0gfds3s',
        category: 'show',
        date: '2016-06-10',

    }, {
        client: 'Vuzu Amp',
        title: 'Pop Bottles Bloem event',
        image: 'assets/thumbs/pop-bottles-bloem_tn.jpg',
        banner: 'assets/img/pop-bottles-bloem.jpg',
        description: 'Pop Mabodlela, Pop Champagne! We shut down Macufe weekend with a heated lineup of the freshest DJ\'s. A TV Spot for Vuzu Amp\'s Pop Bloem event.',
        video: 'https://www.youtube.com/embed/zZK_vmHo21g',
        category: 'event',
        date: '2016-06-10',

    }, {
        client: 'Vuzu Amp',
        title: 'Pop Bottles Grand Finale 2015 event',
        image: 'assets/thumbs/pop-bottles-finale_tn.jpg',
        banner: 'assets/img/pop-bottles-finale.jpg',
        description: 'Pop Mabodlela, Pop Champagne! We kick-start the festive season at Hip Hop\'s finest lifestyle event. A TV Spot for Vuzu Amp\'s Pop Bottles Finale event.',
        video: 'https://www.youtube.com/embed/moOUdKbjNJU',
        category: 'event',
        date: '2016-06-10',

    }, {
        client: 'Vuzu Amp',
        title: 'Ultimate X',
        image: 'assets/thumbs/vusu-amp-ultimate-x_tn.jpg',
        banner: 'assets/img/vusu-amp-ultimate-x.jpg',
        description: 'SA\'s premier action sports festival is bigger, better and faster with the dopiest stunts and skills at this epic extreme sports showdown! A TV spot for Vuzu Amps Ultimate X event.',
        video: 'https://www.youtube.com/embed/nAVUWBaTczA',
        category: 'event',
        date: '2016-06-10',

    }, {
        client: 'Mzanzi Magic Music',
        title: 'Back To The Old Skool',
        image: 'assets/thumbs/back-to-the-old-school_tn.jpg',
        banner: 'assets/img/back-to-the-old-school.jpg',
        description: 'A fully loaded global celebration of old school house music from the golden era. A TV spot for Mzansi Magic Music\'s Back to the Old Skool event.',
        video: 'https://www.youtube.com/embed/vJMsVw-uDRc',
        category: 'event',
        date: '2016-06-10',

    }, {
        client: 'Channel O',
        title: 'Maftown Heights Special',
        image: 'assets/thumbs/maftown-heights_tn.jpg',
        banner: 'assets/img/maftown-heights.jpg',
        description: '"Off to the Maftown Heights, as soon as you reach it you tell me what is sounds like!" It\'s going to be extra lit as Hip Hop\'s finest tear it up in Newtown. A TV spot for Channel O\'s annual Maftown Heights event.',
        video: 'https://www.youtube.com/embed/jLkNXB-tS7A',
        category: 'event',
        date: '2016-06-10',

    }, {
        client: 'Vuzu Amp',
        title: 'Hot Men on Vuzu Amp',
        image: 'assets/thumbs/promo1_tn.jpg',
        banner: 'assets/img/promo1.jpg',
        description: 'Vuzu Amp brings you hotness amplified with the hottest series featuring the best male actors.',
        video: 'https://www.youtube.com/embed/uNdSRedtakA',
        category: 'show',
        date: '2016-06-10',

    }, {
        client: 'BET',
        title: 'Februay Theme Piece',
        image: 'assets/thumbs/feb_highlights_tn.jpg',
        banner: 'assets/img/feb_highlights.jpg',
        description: 'BET gives you the Best of all Local and International Entertainment this Valentines Month!',
        video: 'https://www.youtube.com/embed/pJLQz6i4thY',
        category: 'show',
        date: '2016-07-04',

    }, {
        client: 'Crimson Multimedia Kenya',
        title: 'Fantastic 4 30sec TV Spot',
        image: 'assets/thumbs/fantastic_4_2_tn.jpg',
        banner: 'assets/img/fantastic_4_2.jpg',
        description: 'The year of a new generation of heroes has arrived with the official cinema screening of the Blockbuster movie The Fantastic Four! A TV spot for the cinematic premier of Fantastic Four in East &amp; West Africa.',
        video: 'https://www.youtube.com/embed/Gm8-_J6nF1Y',
        category: 'show',
        date: '2016-06-10',

    }, {
        client: 'BET',
        title: 'BET Awards 2016 Competition',
        image: 'assets/thumbs/BET-Awards-2016-Competition_tn.jpg',
        banner: 'assets/img/BET-Awards-2016-Competition.jpg',
        description: 'BET celebrates the best of black talent with their exclusive annual awards show.',
        video: 'https://www.youtube.com/embed/PNOXvuq4WfA',
        category: 'show',
        date: '2017-04-12',

    }, {
        client: 'BET',
        title: 'BET September Theme',
        image: 'assets/thumbs/BET-September-Theme_tn.jpg',
        banner: 'assets/img/BET-September-Theme.jpg',
        description: '',
        video: 'https://www.youtube.com/embed/oGTOT0dhQOg',
        category: 'show',
        date: '2017-04-12',

    }, {
        client: 'BET',
        title: 'Simphiwe Dana Symphony Experience',
        image: 'assets/thumbs/BET-Simphiwe-Dana-Symphony-Experience_tn.jpg',
        banner: 'assets/img/BET-Simphiwe-Dana-Symphony-Experience.jpg',
        description: 'A live musical experience of epic proportions with the soulful sounds of Simphiwe Dana. A TV spot for BET\'s Simphiwe Dana Symphony Experience event.',
        video: 'https://www.youtube.com/embed/qksamhD9ZEI',
        category: 'event',
        date: '2017-04-12',

    }, {
        client: 'Channel O',
        title: 'Champions Theme',
        image: 'assets/thumbs/Channel-O-Champions-Theme_tn.jpg',
        banner: 'assets/img/Channel-O-Champions-Theme.jpg',
        description: 'They tried to put out our flame but now we ignite the game with the best of local music! An epic TV spot for Channel O\'s Champions theme.',
        video: 'https://www.youtube.com/embed/znpIjhroMCs',
        category: 'show',
        date: '2017-04-12',

    }, {
        client: 'Channel O',
        title: 'Head Honcho 8th Birthday',
        image: 'assets/thumbs/Channel-O-Head-Honcho-8th-Birthday_tn.jpg',
        banner: 'assets/img/Channel-O-Head-Honcho-8th-Birthday.jpg',
        description: 'The year is never over without the Head Honcho Birthday Bash where we party like crazy! A TV spot for the Head Honcho Birthday Bash event in association with Channel O.',
        video: 'https://www.youtube.com/embed/shr7Qm7MJNE',
        category: 'event',
        date: '2017-04-12',

    }, {
        client: 'Channel O',
        title: 'Maftown Heights 2016',
        image: 'assets/thumbs/Channel-O-Maftown-Heights-2016_tn.jpg',
        banner: 'assets/img/Channel-O-Maftown-Heights-2016.jpg',
        description: '"Off to the Maftwown Heights, as soon as you reach it you tell me what is sounds like!" A Channel O special showcasing the best moments from the Maftown Heights 2016 concert.',
        video: 'https://www.youtube.com/embed/kkIXzfmkPjY',
        category: 'event',
        date: '2017-04-12',

    }, {
        client: 'Channel O',
        title: 'Major League Gardens',
        image: 'assets/thumbs/Channel-O-Major-League-Gardens_tn.jpg',
        banner: 'assets/img/Channel-O-Major-League-Gardens.jpg',
        description: 'OH Gawd its Major League! We bring the heat in a major way with a TV spot for Channel O\'s Major League Gardens event.',
        video: 'https://www.youtube.com/embed/9Dpxnw4jrPo',
        category: 'event',
        date: '2017-04-12',

    }, {
        client: 'Channel O',
        title: 'Movie Music Fest',
        image: 'assets/thumbs/Channel-O-Movie-Music-Fest_tn.jpg',
        banner: 'assets/img/Channel-O-Movie-Music-Fest.jpg',
        description: 'An ultimate music extravaganza with a world-class visual stage production that takes you on a musical journey like no other! A TV spot for Channel O\'s Movie Music Fest event.',
        video: 'https://www.youtube.com/embed/GYdUuZEh4rc',
        category: 'event',
        date: '2017-04-12',

    }, {
        client: 'Channel O',
        title: 'Stay Fresh Saturday',
        image: 'assets/thumbs/Channel-O-Stay-Fresh-Saturday_tn.jpg',
        banner: 'assets/img/Channel-O-Stay-Fresh-Saturday.jpg',
        description: 'A musical fiasco with the best in the game! A TV spot for Channel O\'s Stay Fresh Saturdays event.  ',
        video: 'https://www.youtube.com/embed/v2RwlhHZY7A',
        category: 'event',
        date: '2017-04-12',

    }, {
        client: 'DSTV',
        title: 'iRock Festival',
        image: 'assets/thumbs/DSTV-iRock-Rustenburg_tn.jpg',
        banner: 'assets/img/DSTV-iRock-Rustenburg.jpg',
        description: 'The ultimate musical experience goes to Rustenburg. A TV spot for DSTV\'s iRock Rustenburg Music Festival event.',
        video: 'https://www.youtube.com/embed/-gjh0Vx3UVQ',
        category: 'event',
        date: '2017-04-12',

    }, {
        client: 'BET',
        title: 'Awards',
        image: 'assets/thumbs/bet_awards_tn.jpg',
        banner: 'assets/img/bet_awards.jpg',
        description: 'A party is always more than just the date. It\'s the people, the place, the performances and the vibe that makes it all come together. It\'s an event! We drive ticket sales and attendance with this TV spot.',
        video: 'https://www.youtube.com/embed/iLQYsHRT1tY',
        category: 'show',
        date: '2016-07-04',

    }, {
        client: 'BET',
        title: 'April Themepiece',
        image: 'assets/thumbs/april_theme_tn.jpg',
        banner: 'assets/img/april_theme.jpg',
        description: 'BET brings you A-List entertainment all day, everyday with the best shows you cannot miss! A TV spot for BET\'s April theme.',
        video: 'https://www.youtube.com/embed/-7Vus9k7srQ',
        category: 'show',
        date: '2016-07-04',

    }, {
        client: 'Mzansi Magic Music',
        title: 'Night of the Hitmaker',
        image: 'assets/thumbs/Mzansi-Magic-Music-Night-of-the-Hitmaker_tn.jpg',
        banner: 'assets/img/Mzansi-Magic-Music-Night-of-the-Hitmaker.jpg',
        description: 'A summer celebration with an all-star lineup of soul music\'s best. A TV spot for Mzansi Magic Music\'s Night of the Hit Maker event.',
        video: 'https://www.youtube.com/embed/aI_xEQMMUvc',
        category: 'event',
        date: '2017-04-12',

    }, {
        client: 'Vuzu AMP',
        title: 'Day Dream',
        image: 'assets/thumbs/Vuzu-AMP-Daydream_tn.jpg',
        banner: 'assets/img/Vuzu-AMP-Daydream.jpg',
        description: '',
        video: 'https://www.youtube.com/embed/zgHU20jsBbM',
        category: 'event',
        date: '2017-04-12',

    }, {
        client: 'Vuzu AMP',
        title: 'Good Jumble',
        image: 'assets/thumbs/Vuzu-AMP-Good-Jumble_tn.jpg',
        banner: 'assets/img/Vuzu-AMP-Good-Jumble.jpg',
        description: 'Outfit on and fabulousness on lock with stylish and slaying celebrity thrift clothing! A TV spot for The Good Jumble Celebrity Sale Pop Up Market brought to you by Vuzu Amp.',
        video: 'https://www.youtube.com/embed/ihoWISxyl38',
        category: 'event',
        date: '2017-04-12',

    }, {
        client: 'Vuzu AMP',
        title: 'Pop Bottles CPT',
        image: 'assets/thumbs/Vuzu-AMP-Pop-Bottles-CPT_tn.jpg',
        banner: 'assets/img/Vuzu-AMP-Pop-Bottles-CPT.jpg',
        description: 'A party is always more than just the date. It\'s the people, the place, the performances and the vibe that makes it all come together. It\'s an event! We drive ticket sales and attendance with this TV spot.',
        video: 'https://www.youtube.com/embed/qVoOfHJ5VnI',
        category: 'event',
        date: '2017-04-12',

    }, {
        client: 'BET',
        title: 'Social Media',
        image: 'assets/thumbs/social_media_tn.jpg',
        banner: 'assets/img/social_media.jpg',
        description: 'Be the first to get the low down on what\'s hot and happening in all local and international entertainment with just a like, click, tweet and share. Be all you and in the know with BET Social. A TV spot promoting BET\'s Social Media pages.',
        video: 'https://www.youtube.com/embed/UXCJdz6mDyg',
        category: 'advert',
        date: '2016-07-04',

    }, {
        client: 'Kwesé',
        title: 'Kwesé Launch Advert',
        image: 'assets/thumbs/Kwese-Launch-Advert_tn.jpg',
        banner: 'assets/img/Kwese-Launch-Advert.jpg',
        description: 'An African first, exclusive entertainment with hit movies, premier series, faith, documentaries, news and much more all at your fingertips. Kwese, Beyond TV! A TV spot for Kwese\'s Brand Launch.',
        video: 'https://www.youtube.com/embed/AO1yRqPVM8E',
        category: 'show',
        date: '2017-04-12',

    }, {
        client: 'Kwesé',
        title: 'Kwesé Multi Platform Advert',
        image: 'assets/thumbs/Kwese-Multi-Platform-Advert_tn.jpg',
        banner: 'assets/img/Kwese-Multi-Platform-Advert.jpg',
        description: 'The best of African Entertainment. A digital campaign for Kwese’s Multi entertainment Platform.',
        video: 'https://www.youtube.com/embed/4KxWHJyJbBc',
        category: 'advert',
        date: '2017-04-12',

    }, {
        client: 'Kwesé',
        title: 'Kwesé Pay As You Watch Advert',
        image: 'assets/thumbs/Kwese-Pay-As-You-Watch-Advert_tn.jpg',
        banner: 'assets/img/Kwese-Pay-As-You-Watch-Advert.jpg',
        description: 'Its time to change the game as we unlock a world of entertainment with the best payment options that suit your pocket! A TV spot for Kwese\'s Pay as you watch.',
        video: 'https://www.youtube.com/embed/sGKar32sGb8',
        category: 'show',
        date: '2017-04-12',

    }, {
        client: 'BET',
        title: 'Muhammad Ali Peoples Champ',
        image: 'assets/thumbs/mohammed_ali_tn.jpg',
        banner: 'assets/img/mohammed_ali.jpg',
        description: 'The luminary, the legend, the legacy. A TV spot for BET\'s Muhammad Ali The People\'s Champ.',
        video: 'https://www.youtube.com/embed/c1BbjMK-uqc',
        category: 'show',
        date: '2016-07-04',

    }, {
        client: 'Kwesé',
        title: 'Kwesé Premium Local Entertainment Advert',
        image: 'assets/thumbs/Kwese-Premium-Local-Entertainment-Advert_tn.jpg',
        banner: 'assets/img/Kwese-Premium-Local-Entertainment-Advert.jpg',
        description: '',
        video: 'https://www.youtube.com/embed/ENJuCP5vnog',
        category: 'show',
        date: '2017-04-12',

    }, {
        client: 'Kwesé',
        title: 'Kwesé TV Generic Advert',
        image: 'assets/thumbs/Kwese-TV-Generic-Advert_tn.jpg',
        banner: 'assets/img/Kwese-TV-Generic-Advert.jpg',
        description: '',
        video: 'https://www.youtube.com/embed/iPlA2yXqYMs',
        category: 'show',
        date: '2017-04-12',

    }, {
        client: 'BET',
        title: 'Being Mary Jane Marathon',
        image: 'assets/thumbs/being_maryjane_tn.jpg',
        banner: 'assets/img/being_maryjane.jpg',
        description: 'BET brings you the Being Mary-Jane Marathon back to back from S1 so relax and unwind as we fulfill your fantasy. A TV spot for BET\'s Being Mary Jane Marathon.',
        video: 'https://www.youtube.com/embed/hZV-F0ZhPzk',
        category: 'show',
        date: '2016-07-04',

    }, {
        client: 'Channel O',
        title: 'Back to the City',
        image: 'assets/thumbs/Channel-O-Back-to-the-City_tn.jpg',
        banner: 'assets/img/Channel-O-Back-to-the-City.jpg',
        description: 'Everything street culture with the biggest Hip Hop Festival in Africa! A TV spot for Channel O\'s Back to The City Festival event.',
        video: 'https://www.youtube.com/embed/UamW4OIPV9s',
        category: 'event',
        date: '2017-04-25',

    }, {
        client: 'BET',
        title: 'June Highlights',
        image: 'assets/thumbs/june_highlights_tn.jpg',
        banner: 'assets/img/june_highlights.jpg',
        description: 'BET stays giving you the Best of all Local and International Entertainment this Youth Month! A TV spot for BET\'s June theme-piece.',
        video: 'https://www.youtube.com/embed/AOrOoh-xwvo',
        category: 'show',
        date: '2016-07-04',

    }, {
        client: 'Mzansi Magic Music',
        title: 'Ekurhuleni Easter Experience',
        image: 'assets/thumbs/Mzansi-Magic-Music-Ekurhuleni-Easter-Experience_tn.jpg',
        banner: 'assets/img/Mzansi-Magic-Music-Ekurhuleni-Easter-Experience.jpg',
        description: 'The best Easter festival in the city of Ekhuruleni!  A TV spot for Mzansi Magic Music\'s Ekhuruleni Easter Experience event.',
        video: 'https://www.youtube.com/embed/AxZXvQMcGew',
        category: 'event',
        date: '2017-04-25',

    }, {
        client: 'Vuzu Amp',
        title: 'Sneaker Exchange',
        image: 'assets/thumbs/Vuzu-Amp-Sneaker-Exchange_tn.jpg',
        banner: 'assets/img/Vuzu-Amp-Sneaker-Exchange.jpg',
        description: 'Always put your best foot forward because it’s always a jump at the doppest sneaker event! We engage in sneaker talk with the top Hip Hop reps to find out about their sneaker collections.',
        video: 'https://www.youtube.com/embed/5MZrTVsFGD8',
        category: 'event',
        date: '2017-04-25',

    }, {
        client: 'Vuzu Amp',
        title: 'Pop Bottles Mauritius',
        image: 'assets/thumbs/Vuzu-Amp-Pop-Bottles-Mauritius_tn.jpg',
        banner: 'assets/img/Vuzu-Amp-Pop-Bottles-Mauritius.jpg',
        description: 'Pop Mabodlela, Pop Champagne! We\'re taking Pop Bottles to international heights! A TV Spot for Vuzu Amp\'s Pop Bottles Mauritius event.',
        video: 'https://www.youtube.com/embed/1j9Jm1C0Gck',
        category: 'event',
        date: '2017-04-25',

    }, {
        client: 'BET',
        title: 'Freedom Month',
        image: 'assets/thumbs/BET-Freedom-Month_tn.jpg',
        banner: 'assets/img/BET-Freedom-Month.jpg',
        description: 'BET gives you the Best of all Local and International Entertainment this Freedom Month! A TV spot for BET\'s April theme-piece.',
        video: 'https://www.youtube.com/embed/jEORnJf1yu4',
        category: 'show',
        date: '2017-04-25',

    }, {
        client: 'BET',
        title: 'Generic Billboard',
        image: 'assets/thumbs/BET_Generic_Billboard_3x6_V9_tn.jpg',
        banner: 'assets/img/BET_Generic_Billboard_3x6_V9.jpg',
        description: 'A Generic billboard for DSTV, Sub-Saharan African direct broadcast satellite service owned by MultiChoice.',
        video: '',
        category: 'advert',
        date: '2017-05-10',

    }, {
        client: 'Vuzu Amp',
        title: 'Braxton Family Values',
        image: 'assets/thumbs/Braxton-Family-Values_tn.jpg',
        banner: 'assets/img/Braxton-Family-Values.jpg',
        description: 'After an emotional family healing retreat, the Braxton’s work to repair their sisterhood. A digital campaign for VUZU Amp’s Braxton Family Values.',
        video: '',
        category: 'advert',
        date: '2017-05-10',

    }, {
        client: 'Vuzu Amp',
        title: 'Flex & Shanice',
        image: 'assets/thumbs/Flex&Shanice_tn.jpg',
        banner: 'assets/img/Flex&Shanice.jpg',
        description: 'Pop singer Shanice and Actor Alexander take a drastic decision of moving their extended family into a rental house. A digital campaign for VUZU Amp’s reality show Flex and Shanice.',
        video: '',
        category: 'advert',
        date: '2017-05-10',

    }, {
        client: 'Vuzu Amp',
        title: 'Power',
        image: 'assets/thumbs/Power_tn.jpg',
        banner: 'assets/img/Power.jpg',
        description: 'Money. Success. Respect. Here you can have it all. A digital campaign for Vuzu Amp’s hit crime drama Power',
        video: '',
        category: 'advert',
        date: '2017-05-10',

    }, {
        client: 'Vuzu Amp',
        title: 'Survivors Remorse',
        image: 'assets/thumbs/Survivors-Remorse_tn.jpg',
        banner: 'assets/img/Survivors-Remorse.jpg',
        description: 'Pro Money means Pro problems. A digital campaign for VUZU AMP’s comedic-drama Survivors Remorse.',
        video: '',
        category: 'advert',
        date: '2017-05-10',

    }, {
        client: 'Vuzu Amp',
        title: 'Pop Bottles Durban 2017',
        image: 'assets/thumbs/Pop-Bottles-Durban_tn.jpg',
        banner: 'assets/img/Pop-Bottles-Durban.jpg',
        description: 'Pop bottles brings you a wavy lifestyle Sunday session with the best premium lineup! A TV Spot for Vuzu Amp\'s Pop Bottles Durban event.',
        video: 'https://www.youtube.com/embed/_1rGEHfsdqA',
        category: 'event',
        date: '2017-07-24',

    }, {
        client: 'Vuzu Amp',
        title: 'Jack Daniels Boomtown 2017',
        image: 'assets/thumbs/Boomtown-2017_tn.jpg',
        banner: 'assets/img/Boomtown-2017.jpg',
        description: 'Time to step out in style at the Vodacom Durban July! A TV spot for Jack Daniels Boomtown brought to you by Vuzu Amp.',
        video: 'https://www.youtube.com/embed/eadCOa8GvPY',
        category: 'event',
        date: '2017-07-24',

    }, {
        client: 'Mzansi Magic Music',
        title: 'Pride Music Show',
        image: 'assets/thumbs/PRIDE_MUSIC_SHOW_tn.jpg',
        banner: 'assets/img/PRIDE_MUSIC_SHOW.jpg',
        description: 'It\'s all about soul with SA\'s hottest stars. A TV spot for the Pride Music Show brought to you by Mzansi Magic Music.',
        video: 'https://www.youtube.com/embed/LbZXs7V3j9g',
        category: 'event',
        date: '2017-07-24',

    }, {
        client: 'Channel 0',
        title: 'The Official Official Durban July Concert',
        image: 'assets/thumbs/Official-Durban-July-Concert_tn.jpg',
        banner: 'assets/img/Official-Durban-July-Concert.jpg',
        description: 'Great music by international stars. A TV spot for Channel O\'s Official Durban July Launch Concert.',
        video: 'https://www.youtube.com/embed/rBfAxyt5VLA',
        category: 'event',
        date: '2017-07-24',

    }, {
        client: 'Channel 0',
        title: 'Fact Durban Rocks 2017',
        image: 'assets/thumbs/CHO-Fact-Durban-Rocks-2_tn.jpg',
        banner: 'assets/img/CHO-Fact-Durban-Rocks.jpg',
        description: 'Aint no party like a Fact party! Channel O catches up with our favorite artists in Durban to find out who they really are and where their musical journeys started.',
        video: 'https://www.youtube.com/embed/zeVVZ0SoL-A',
        category: 'event',
        date: '2017-07-24',

    }, {
        client: 'Channel 0',
        title: 'Cross Culture Music Festival',
        image: 'assets/thumbs/Channel-O-Cross-Culture-Music-Festival_tn.jpg',
        banner: 'assets/img/Channel-O-Cross-Culture-Music-Festival.jpg',
        description: 'Cultures collide with a fusion of music and everything that makes culture pop! A TV spot for Channel O\'s Cross Culture Music Festival event.',
        video: 'https://www.youtube.com/embed/9dpOk0t3YyY',
        category: 'event',
        date: '2017-10-09',

    }, {
        client: 'Channel 0',
        title: 'Major League Gardens Joburg 2017',
        image: 'assets/thumbs/Channel-O-Major-League-Gardens-Joburg-2017_tn.jpg',
        banner: 'assets/img/Channel-O-Major-League-Gardens-Joburg-2017.jpg',
        description: 'A party is always more than just the date. It\'s the people, the place, the performances and the vibe that makes it all come together. It\'s an event! We drive ticket sales and attendance with this TV spot.',
        video: 'https://www.youtube.com/embed/ICbSqXcwzN0',
        category: 'event',
        date: '2017-10-09',

    }, {
        client: 'Mzansi Magic Music',
        title: 'Ekhurhuleni Spring Jump Off Carnival',
        image: 'assets/thumbs/Mzansi-Magic-Music-Ekhurhuleni-Spring-Jump-Off-Carnival_tn.jpg',
        banner: 'assets/img/Mzansi-Magic-Music-Ekhurhuleni-Spring-Jump-Off-Carnival.jpg',
        description: 'Start off your spring with nothing but good vibes! A TV spot for Channel O\'s Spring Jump Off 10 year celebration event.',
        video: 'https://www.youtube.com/embed/4U6l1BvN_-U',
        category: 'event',
        date: '2017-10-09',

    }, {
        client: 'Vuzu Amp',
        title: 'Pop Bottles Birthday Edition 2017',
        image: 'assets/thumbs/Vuzu-Amp-Pop-Bottles-Birthday-Edition-2017_tn.jpg',
        banner: 'assets/img/Vuzu-Amp-Pop-Bottles-Birthday-Edition-2017.jpg',
        description: 'A party is always more than just the date. It\'s the people, the place, the performances and the vibe that makes it all come together. It\'s an event! We drive ticket sales and attendance with this TV spot.',
        video: 'https://www.youtube.com/embed/RB4Lx8HbUOs',
        category: 'event',
        date: '2017-10-09',

    },
    {
        client: 'Channel O',
        title: 'Youth Day Celebration',
        image: 'assets/thumbs/cho_youthday_tn.jpg',
        banner: 'assets/img/cho_youthday.jpg',
        description: 'A party is always more than just the date. It\'s the people, the place, the performances and the vibe that makes it all come together. It\'s an event! We drive ticket sales and attendance with this TV spot.',
        video: 'https://www.youtube.com/embed/zqTlusQxFLI',
        category: 'event',
        date: '2016-07-04',

    },
    {
        client: '1Magic',
        title: 'The Grand White Dinner',
        image: 'assets/thumbs/grand_white_dinner_tn.jpg',
        banner: 'assets/img/grand_white_dinner.jpg',
        description: 'Its all things glamour with SA\'s top premium and lavish affair! A TV spot for The Grand White Dinner brought to you by 1Magic.',
        video: 'https://www.youtube.com/embed/xr2A0y8ibvk',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'YFM',
        title: '#Y21',
        image: 'assets/thumbs/year_21_tn.jpg',
        banner: 'assets/img/year_21.jpg',
        description: '21 years of keeping it real, breaking the charts, legendary moments and 21 years of youth! A TV spot for Channel O\'s Y21 event.',
        video: 'https://www.youtube.com/embed/DmqGorkykQ0',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'Channel O',
        title: 'SA\'s Most Wanted Youth Day Celebration',
        image: 'assets/thumbs/youthday_2018_tn.jpg',
        banner: 'assets/img/youthday_2018.jpg',
        description: 'Everything lit on lockdown with the most wanted! A TV spot for SA\'s Most Wanted Youth Day Celebration event by Channel O.',
        video: 'https://www.youtube.com/embed/oE5wKZoFRiw',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'Channel O',
        title: 'Absolute One Source Live',
        image: 'assets/thumbs/one_source_live_tn.jpg',
        banner: 'assets/img/one_source_live.jpg',
        description: 'Join the creative revolution with a festival celebrating African creativity. A TV spot for Channel O\'s One Source Live event.',
        video: 'https://www.youtube.com/embed/zFc0XxUjDP0',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'Channel O',
        title: 'Nasty C Strings and Bling Live',
        image: 'assets/thumbs/nasty_c_tn.jpg',
        banner: 'assets/img/nasty_c.jpg',
        description: 'He did say he would never quit! A TV spot for the Nasty C Strings and Bling Live event in association with Channel O.',
        video: 'https://www.youtube.com/embed/0aRnMIV5Apw',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'Mzansi Magic',
        title: 'Glenlivet Jazz Town 2018',
        image: 'assets/thumbs/jazz_town_tn.jpg',
        banner: 'assets/img/jazz_town.jpg',
        description: 'Taste the finer things in life at the Durban July with scintillating live music from legendary jazz greats. A TV spot for Mzansi Magic\'s Glenlivet Jazz Town event.',
        video: 'https://www.youtube.com/embed/ZnlE9StqLwM',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'Channel O',
        title: 'Major League Gardens Spring 2018',
        image: 'assets/thumbs/major_league_2018_tn.jpg',
        banner: 'assets/img/major_league_2018.jpg',
        description: 'A party is always more than just the date. It\'s the people, the place, the performances and the vibe that makes it all come together. It\'s an event! We drive ticket sales and attendance with this TV spot.',
        video: 'https://www.youtube.com/embed/9os2CLWvNSY',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'Channel O',
        title: 'Dankie San Tribute',
        image: 'assets/thumbs/dankie_san_tn.jpg',
        banner: 'assets/img/dankie_san.jpg',
        description: '<p>Dankie San means "thank you brother" in South African slang. Linda Mkhize, better known as Pro and formerly known as Prokid, died in 2018 after a seizure, he was 37.</p><p>Pro was, by and large, the rapper responsible for the style and use of the zulu language in South African hip-hop music. He was a formidable wordsmith with the allure of pure street essence. Very much a proud Soweto resident, he often stated in his music that he was Soweto\'s number one. He was your rapper\'s favourite rapper and, although a decorated musician, he never got the recognition he deserved from the music industry.</p><p>Channel O briefed Originators TV to create a promo for a special event that celebrated the life of Pro Kid. Dankie San, a Hip Hop Tribute. We say thank you to Pro Kid for gracing us with a legendary body of South African hip-hop. We created a TV spot that captured the essence of Pro\'s career, his music, his signature slogans as well as highlight the performing artists that he inspired.</p>',
        video: 'https://www.youtube.com/embed/qsna4NVQfOI',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'DSTV',
        title: 'Oh Ship 2018',
        image: 'assets/thumbs/oh_ship_tn.jpg',
        banner: 'assets/img/oh_ship.jpg',
        description: 'Party with your favorite captains Fresh and Euphonik with music bigger and better! A TV spot for DSTV\'s Oh Ship 2018 event.',
        video: 'https://www.youtube.com/embed/9305rSbccIA',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'Channel O',
        title: 'Unlocks Weekend',
        image: 'assets/thumbs/unlock_weekend_tn.jpg',
        banner: 'assets/img/unlock_weekend.jpg',
        description: 'A party is always more than just the date. It\'s the people, the place, the performances and the vibe that makes it all come together. It\'s an event! We drive ticket sales and attendance with this TV spot.',
        video: 'https://www.youtube.com/embed/T7_C-UUFRbk',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'Channel O',
        title: 'Moment',
        image: 'assets/thumbs/moment_tn.jpg',
        banner: 'assets/img/moment.jpg',
        description: 'From new kid on the block to being the hotshot music authority, its 21 years of the best local tunes from the number 1 urban music channel. A TV spot for the Channel O Moment event at the DSTV Delicious Concert.',
        video: 'https://www.youtube.com/embed/HotPiLqnSxU',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'Mzansi Magic',
        title: 'Being Bonang S1 Launch',
        image: 'assets/thumbs/bonang_tn.jpg',
        banner: 'assets/img/bonang.jpg',
        description: 'Keep your style on lock, your crown straight and your heart on your sleeves with the prmier of Being Bonang Season 1 on Mzansi Magic.',
        video: 'https://www.youtube.com/embed/6wfqbekvidg',
        category: 'show',
        date: '2019-01-29',

    },
    {
        client: '1Magic',
        title: 'Soul Fest',
        image: 'assets/thumbs/soul_fest_tn.jpg',
        banner: 'assets/img/soul_fest.jpg',
        description: 'The smoothest soothing tunes from internationally acclaimed RnB sensations. A TV spot for 1Magic\'s Soul Fest event.',
        video: 'https://www.youtube.com/embed/WELGzyvQng4',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: '1Magic',
        title: 'Day Dream',
        image: 'assets/thumbs/day_dream_tn.jpg',
        banner: 'assets/img/day_dream.jpg',
        description: 'Wine and dine in all white style with the best of the best! A TV spot for 1Magic\'s Day Dream event.',
        video: 'https://www.youtube.com/embed/aBeFEGc7a4U',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Soulful Night with Kenny Lattimore',
        image: 'assets/thumbs/soulful_tn.jpg',
        banner: 'assets/img/soulful.jpg',
        description: 'An exclusive affair with international RnB legend! A TV spot for A Soulful Night with Kenny Lattimore in association with Mzansi Magic Music.',
        video: 'https://www.youtube.com/embed/fv1Xtivt5Y4',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Green Room Sessions',
        image: 'assets/thumbs/green_room_tn.jpg',
        banner: 'assets/img/green_room.jpg',
        description: 'A 1<sup>st</sup> class experience with thrilling performances by the best local and international artists. A TV spot for Mzansi Magic Music\'s Green Room Sessions.',
        video: 'https://www.youtube.com/embed/3DZUdbgm-mM',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'Channel O',
        title: 'Absolute One Source Live',
        image: 'assets/thumbs/one_source_live_tn.jpg',
        banner: 'assets/img/one_source_live.jpg',
        description: 'Join the creative revolution with a festival celebrating African creativity. A TV spot for Channel O\'s One Source Live event.',
        video: 'https://www.youtube.com/embed/K962VaH63uc',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'Channel O',
        title: 'Major League Gardens 2018',
        image: 'assets/thumbs/gardens_2018_tn.jpg',
        banner: 'assets/img/gardens_2018.jpg',
        description: 'A party is always more than just the date. It\'s the people, the place, the performances and the vibe that makes it all come together. It\'s an event! We drive ticket sales and attendance with this TV spot.',
        video: 'https://www.youtube.com/embed/otRBOsW0u2A',
        category: 'event',
        date: '2019-01-29',

    },
    {
        client: 'Channel O',
        title: 'The Artist Series',
        image: 'assets/thumbs/okmalumkoolkat_tn.jpg',
        banner: 'assets/img/okmalumkoolkat.jpg',
        description: '',
        category: 'content',
        date: '2019-09-15',
        video_campaigns: {
            content: [
                {
                    video: {
                        url: 'https://www.youtube.com/embed/Ykud5wBQixU?rel=0',
                        type: 'stream',
                    }
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/kYGNgnjJ5uA?rel=0',
                        type: 'stream',
                    }
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/yMiQtgnhYEU?rel=0',
                        type: 'stream',
                    }
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/EH7vowaCDmE?rel=0',
                        type: 'stream',
                    }
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/9iav0Ncb_kE?rel=0',
                        type: 'stream',
                    }
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/de0iXQSxQYo?rel=0',
                        type: 'stream',
                    }
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/1d6faEfOxEI?rel=0',
                        type: 'stream',
                    }
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/hyacP5O5t9w?rel=0',
                        type: 'stream',
                    }
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/R7LRel_TgNM?rel=0',
                        type: 'stream',
                    }
                },
            ]
        },
        template: 'content',
    },
    {
        client: 'Mnet Movies',
        title: 'M Net Movies Creed II Pre Screening',
        image: 'assets/thumbs/2019_06_06_creed2_tn.jpg',
        banner: 'assets/img/2019_06_06_creed2.jpg',
        description: 'An epic story of pain and glory in an exclusive Pre Screening of Creed 2. Don\'t go down without a fight because the stakes have never been higher! A TV spot for M-Net Movies Premier of Creed 2.',
        video: 'https://www.youtube.com/embed/22HMFDZnAfs',
        category: 'show',
        date: '2019-06-06',

    },
    {
        client: 'SofnFree',
        title: 'SofnFree HairXperts',
        image: 'assets/thumbs/2019_06_06_softnfree_tn.jpg',
        banner: 'assets/img/2019_06_06_softnfree.jpg',
        description: 'Style and Slay! South Africa\'s number 1 hairstyling competition, the SofnFree 2019 Hair Experts Masters Challenge. A TV spot for the SofnFree Hair Experts Competition.',
        video: 'https://www.youtube.com/embed/-wCfgRQXI5c',
        category: 'event',
        date: '2019-06-06',

    },
    {
        client: '1Magic',
        title: '1Magic The Hate U Give',
        image: 'assets/thumbs/2019_06_06_hate_u_give_tn.jpg',
        banner: 'assets/img/2019_06_06_hate_u_give.jpg',
        description: 'An exclusive Pre Screening event of the gripping movie "The Hate You Give" where Starr Cater\'s world is shattered when a trigger pull turns her world into chaos and through the pain she tries to find her voice. A TV spot for 1 Magic\'s The Hate You Give.',
        video: 'https://www.youtube.com/embed/LUWD8anbzJE',
        category: 'show',
        date: '2019-06-06',

    },
    {
        client: 'BET',
        title: 'BET Yes to Campaign',
        image: 'assets/thumbs/2019_06_06_bet_yes_campaign_1_tn.jpg',
        banner: 'assets/img/2019_06_06_bet_yes_campaign.jpg',
        description: 'BET says yes to the best of local and international entertainment. A YES to BET digital campaign.',
        video: '',
        category: 'advert',
        date: '2019-06-06',
        img_campaigns: [
            'assets/img/2019_06_06_bet_yes_campaign_1.jpg',
            'assets/img/2019_06_06_bet_yes_campaign_2.jpg',
            'assets/img/2019_06_06_bet_yes_campaign_3.jpg',
            'assets/img/2019_06_06_bet_yes_campaign_4.jpg',
        ],

    },
    {
        client: 'BET',
        title: 'BET Made in Africa',
        image: 'assets/thumbs/2019_06_06_made_in_africa_4_tn.jpg',
        banner: 'assets/img/2019_06_06_made_in_africa.jpg',
        description: 'BET puts the spotlight on African fashion. A digital campaign for the premier of BET’s fashion reality show Made in Africa',
        video: '',
        category: 'advert',
        date: '2019-06-06',
        img_campaigns: [
            'assets/img/2019_06_06_made_in_africa_1.jpg',
            'assets/img/2019_06_06_made_in_africa_2.jpg',
            'assets/img/2019_06_06_made_in_africa_3.jpg',
            'assets/img/2019_06_06_made_in_africa_4.jpg',
            'assets/img/2019_06_06_made_in_africa_5.jpg',
        ],

    },
    {
        client: 'Cell C Black',
        title: 'Cell C Black Campaigns',
        image: 'assets/thumbs/2019_06_11_cell_c_campaign_tn.jpg',
        banner: 'assets/img/2019_06_11_cell_c_campaign.jpg',
        description: 'Entertainment like no other in South Africa! A digital campaign for Cell C Black’s Blockbusters.',
        video: 'https://www.youtube.com/embed/DZ7ubEc3j3A',
        category: 'advert',
        date: '2019-06-06',
        video_campaigns: [
            'https://www.youtube.com/embed/vM1bnzIbJ_I',
        ],

    },
    {
        client: 'Channel 0',
        title: 'Channel O Heritage Month Campaigns',
        image: 'assets/thumbs/2019_06_11_channel_o_campaign_tn.jpg',
        banner: 'assets/img/2019_06_11_channel_o_campaign.jpg',
        description: 'Channel O celebrates heritage month with the best tunes from Kwaito legends! We created a throw back heritage month series of all our favorites for Channel O’s digital platforms.',
        video: 'https://www.youtube.com/embed/38VH60Kq8aI',
        category: 'advert',
        date: '2019-06-06',
        video_campaigns: [
            'https://www.youtube.com/embed/vNTdJiKxv3I',
            'https://www.youtube.com/embed/S8UglcVkdi8',
            'https://www.youtube.com/embed/jlA2U66N2gg',
            'https://www.youtube.com/embed/gjZ4694Jogs',
            'https://www.youtube.com/embed/w9cnRlFRti8',
            'https://www.youtube.com/embed/r_ryeA36eNs',
        ],

    },
    {
        client: 'Channel 0',
        title: 'The Formula',
        image: 'assets/thumbs/2019_08_22_the_formula_tn.jpg',
        banner: 'assets/img/2019_08_22_tira_banner.jpg',
        description: `<p>Channel O briefed Originators TV to create a short-form series that features shape-shifters of the South African music industry. 
        The content has to highlight what one needs to know in order to be a success in the music industry.</p>
        <p><b>Ideation</b></p>
        <p>Fragments of a secret sauce to success in music have been discovered by very few throughout the years.</p>
        <p>For the first time, all the pieces of the secret formula come together in an incredible revelation that will be life transforming for all who experience it.</p>
        <p>In Channel O's Trail-Blazing short-form series, SA's most impactful innovators, executives and artists explore the power moves they made to realise their dreams and their impact on African music at large. Originators TV came up with the concept for The Formula.</p>
        <p><b>The Execution was flawless</b></p>
        <p>Each episode features an authority in music breaking down the key essentials to making it in the music industry.</p>`,
        category: 'content',
        date: '2019-08-22',
        video_campaigns: {
            video: {
                url: 'assets/video/The_Formula_DJ_Tira_x_CHO.mov',
                type: 'hosted',
                image: 'assets/img/2019_08_22_tira_banner.jpg',
            },
            content: [
                {
                    video: {
                        url: 'assets/video/The_Formula_Oskido_x_CHO.mov',
                        type: 'hosted',
                        image: 'assets/img/2019_08_22_oskido_banner.jpg',
                    }
                }, {
                    image: 'assets/img/2019_08_22_dimplez.gif'
                }, {
                    image: 'assets/img/2019_08_22_refiloe.gif',
                }, {
                    video: {
                        url: 'assets/video/The_Formula_DJ_Dimplez_x_CHO.mov',
                        type: 'hosted',
                        image: 'assets/img/2019_08_22_dimplez_banner.jpg',
                    }
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/nHFjggs8nZk?rel=0',
                        type: 'stream',
                    }
                }, {
                    image: 'assets/img/2019_08_22_Vusi_1.jpg',
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/i-fY7xbVuys?rel=0',
                        type: 'stream',
                    }
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/B8R8dnV4vmU?rel=0',
                        type: 'stream',
                    }
                }, {
                    image: 'assets/img/2019_08_22_tim.gif',
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/s1nFGma9hyo?rel=0',
                        type: 'stream',
                    }
                }, {
                    image: 'assets/img/2019_08_22_Amaru_1.jpg'
                }, {
                    image: 'assets/img/2019_08_22_Osmic_1.jpg'
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/qI3DwCl4Ic8?rel=0',
                        type: 'stream',
                    }
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/p-yVkpowEzM?rel=0',
                        type: 'stream',
                    }
                }, {
                    image: 'assets/img/2019_08_22_Slikour_1.jpg'
                }
            ]
        },
        template: 'content',

    },
    {
        client: 'Ummah TV',
        title: 'Ummah',
        image: 'assets/thumbs/2019_09_03_umah_tn.jpg',
        banner: '',
        description: '<p>We set out to create channel imaging that transcends borders and keeps the values of Islam alive in the Islamic countries of Africa. The brief was to come up with a modern, fun and engaging look and feel that maintained the grounded values rooted in islam.</p><p>Ummah means a united community tied together by religion. The broadcast package we created reflected this by the use of omnipresent swirls that connect all aspects of the channel together, symbolising the role of Islam in a Muslim\'s lifestyle.</p><p>All the different elements tie into each other seamlessly. We created design elements that could be used in numerous ways, depending on the application of the elements.</p><p>We produced channel idents, bumpers, lineups, promo packages, stings and show openers. All these graphic elements in one unified package. One nation, one united channel.</p>',
        video: 'https://www.youtube.com/embed/pZNjDR3hoPM',
        category: 'show',
        date: '2019-09-03',
        video_campaigns: [
            {
                banner: '',
                otherImages: [],
            },
        ],
        template: 'content-basic',

    },
    {
        client: 'Channel O',
        title: 'CHO x Bacardi 2019',
        image: 'assets/thumbs/2019_09_15_pink-molly_tn.jpg',
        banner: 'assets/img/2019_09_15_pink-molly.jpg',
        description: 'We dig deep with artist and event goers of the 2019 Bacardi Holiday Club event and find out their favorite things about this 3 day summer event.',
        category: 'content',
        date: '2019-09-15',
        video_campaigns: {
            video: {
                url: 'https://www.youtube.com/embed/wOWhAC-G2Lw?rel=0',
                type: 'stream',
            },
            content: [
                {
                    image: 'assets/img/2019_09_15_Pink-Molly.gif'
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/LMVOmd2_5z8?rel=0',
                        type: 'stream',
                    }
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/huoW0Kale80?rel=0',
                        type: 'stream',
                    }
                }, {
                    image: 'assets/img/2019_09_15_Moonchild.gif'
                }, {
                    image: 'assets/img/2019_09_15_Moonchild_1.gif'
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/oXpYFpbo59M?rel=0',
                        type: 'stream',
                    }
                }
            ]
        },
        template: 'content',

    },
    {
        client: 'Channel O',
        title: 'Castle Lite Unlocks',
        image: 'assets/thumbs/2019_09_15_boity_tn.jpg',
        banner: '',
        description: 'Unlock the unexpected in two cities with two international artists. A TV spot for Channel O\'s Castle Lite Unlocks Bryson Tiller and Travis Scott event.',
        category: 'content',
        date: '2019-09-15',
        video_campaigns: {
            video: {
                url: 'https://www.youtube.com/embed/l0Mg3l0EK50?rel=0',
                type: 'stream',
            },
            content: [
                {
                    image: 'assets/img/2019_09_15_Riky-Rick.gif'
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/_Mm1AwBBnCg?rel=0',
                        type: 'stream',
                    }
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/trbxErhSiBQ?rel=0',
                        type: 'stream',
                    }
                }, {
                    image: 'assets/img/2019_09_15_Lootlove.gif'
                }, {
                    image: 'assets/img/2019_09_15_Boity.gif'
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/J9rGbLo5ahI?rel=0',
                        type: 'stream',
                    }
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/Kxs_pZPy_eU?rel=0',
                        type: 'stream',
                    }
                }
            ]
        },
        template: 'content',

    },
    {
        client: 'Channel O',
        title: 'Pop Bottles Lesotho',
        image: 'assets/thumbs/2019_09_15_CAPITAL_tn.jpg',
        banner: '',
        description: '',
        category: 'content',
        date: '2019-09-15',
        video_campaigns: {
            video: {
                url: 'https://www.youtube.com/embed/046SSBaG9-E?rel=0',
                type: 'stream',
            },
            content: [
                {
                    image: 'assets/img/2019_09_15_Sihle-Ndaba.gif'
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/AZ56TmcP8_8?rel=0',
                        type: 'stream',
                    }
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/dFwH9W7Jw0Y?rel=0',
                        type: 'stream',
                    }
                }, {
                    image: 'assets/img/2019_09_15_Shimza.gif'
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/nA4tNzLu8lg?rel=0',
                        type: 'stream',
                    }
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/Hxg8kCw7AWY?rel=0',
                        type: 'stream',
                    }
                }
            ]
        },
        template: 'content',

    },
    {
        client: 'Channel O',
        title: 'Sneaker Exchange JHB',
        image: 'assets/thumbs/2019_09_15_TEBOGO_tn.jpg',
        banner: '',
        description: 'Always put your best foot forward because it\'s always a jump at the dopiest sneaker event! A TV spot for The Sneaker Exchange Johannesburg.',
        category: 'content',
        date: '2019-09-15',
        video_campaigns: {
            video: {
                url: 'https://www.youtube.com/embed/bb6Hy6Y8TPE?rel=0',
                type: 'stream',
            },
            content: [
                {
                    image: 'assets/img/2019_09_15_Ms-Cosmo.gif'
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/OP3ldqWASUs?rel=0',
                        type: 'stream',
                    }
                }, {
                    video: {
                        url: 'https://www.youtube.com/embed/f8JgHlqmiAk?rel=0',
                        type: 'stream',
                    }
                }, {
                    image: 'assets/img/2019_09_15_Rowlene.gif'
                }, {
                    image: 'assets/img/2019_09_15_Moozlie.gif'
                }
            ]
        },
        template: 'content',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Ayepyep Loves Cape Town',
        image: '',
        banner: '',
        description: 'Amapiano loves Cape Town! A TV spot for Mzansi Magic Music\'s Ayepyep loves Cape Town event.',
        video: 'https://www.youtube.com/embed/dZ47vyj-kC8',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Ayepyep Lovges Durban',
        image: '',
        banner: '',
        description: 'Ashi Ntwela! It\'s a team mapiano take over! A TV spot for Mzansi Magic Music\'s Ayepyep loves Durban event.',
        video: 'https://www.youtube.com/embed/W6qkbzQUvwY',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'The Beach Bioskop',
        image: '',
        banner: '',
        description: 'An open air cinema screening to enjoy with family and friends. A TV spot for Mzansi Magic\'s Beach Bioskop event.',
        video: 'https://www.youtube.com/embed/2njGnCvTEfU',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Dr. Tumi\'s Connect Tour',
        image: '',
        banner: '',
        description: 'Take the anointing nationwide with blessings that will uplift the spirit across SA. A TV spot for Dr. Tumi\'s Connect Tour event brought to you by Mzansi Magic.',
        video: 'https://www.youtube.com/embed/sAGPT-y6vXE',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Genesis',
        image: '',
        banner: '',
        description: 'The start, the beginning, the inception. It\'s about dance; fashion and music with a TV spot celebrating street culture. Genesis All Black brought to you by Channel O.',
        video: 'https://www.youtube.com/embed/nKH4xF5WcIY',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Soweto International Jazz Festival',
        image: '',
        banner: '',
        description: 'It\'s a global celebration of Soweto with local and international stars. A TV spot for the annual Soweto International Jazz Festival brought to you by Mzansi Magic Music.',
        video: 'https://www.youtube.com/embed/KewJ62wTotk',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Magic Music Sessions',
        image: '',
        banner: '',
        description: 'Freedom celebrated with a classic, soulful experience. A TV spot for Magic Music Sessions, brought to you by Mzansi Magic Music.',
        video: 'https://www.youtube.com/embed/10T20fMw5qw',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Davido Live in Concert',
        image: '',
        banner: '',
        description: 'An exclusive one night only epic performance by one of Africa\'s best international acts! A TV spot for Channel O\'s Davido live in concert event.',
        video: 'https://www.youtube.com/embed/h7rjsgjNU1Y',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Old Skool Sundays 2019 Vol 1',
        image: '',
        banner: '',
        description: 'Si shaya ama get down nama grootmaan ekwaito. A TV Spot for Old Skool Sundays, a Mzansi Magic Music event.',
        video: 'https://www.youtube.com/embed/1HNQ3hWNVgc',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Shimza\'s One Man Show Soweto Edition',
        image: '',
        banner: '',
        description: 'Shimza tears it down once again! A TV spot for Shimza\'s One Man show event brought to you by Channel O.',
        video: 'https://www.youtube.com/embed/ITYG67nxF90',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Pop Bottles Birthday Festival',
        image: '',
        banner: '',
        description: 'Don\'t get caught up by the FOMO and come bust it down with your favorite DJ\'s! A TV Spot for Channel O\'s Pop Bottles Birthday Festival event.',
        video: 'https://www.youtube.com/embed/o0pGMPAiW2w',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Soweto 76 Youth Day Celebration',
        image: '',
        banner: '',
        description: 'it\'s time to rise up and pay homage! A TV spot for Channel O\'s Soweto 76 Youth Day Celebration event.',
        video: 'https://www.youtube.com/embed/Uw6pYGzvYQI',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Spring Jump Off 10 Year Celebration',
        image: '',
        banner: '',
        description: '',
        video: 'https://www.youtube.com/embed/Ckm8hP9DoVA',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Fact Durban Rocks Durban July Edition',
        image: '',
        banner: '',
        description: 'Aint no party like a Fact party! Channel O catches up with our favorite artists in Durban to find out who they really are and where their musical journeys started.',
        video: 'https://www.youtube.com/embed/YHhKCf-_bWY',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Jamz on Ice 2019',
        image: '',
        banner: '',
        description: 'It\'s lit to the next level with killer moves on the dance floor and everything on fleek. A TV Spot for Channel O\'s Jamz on Ice event.',
        video: 'https://www.youtube.com/embed/pUjUjzRxi6Y',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Major League Gardens Picnic Social',
        image: '',
        banner: '',
        description: '',
        video: 'https://www.youtube.com/embed/mmEM0TGYkw4',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Pop Bottles Cape Town',
        image: '',
        banner: '',
        description: 'We\'re signing out the summer with a whole lotta sauce! A TV Spot for Channel O\'s Pop Bottles Cape Town event. ',
        video: 'https://www.youtube.com/embed/WvpzeVXas00',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Pop Bottles Durban July',
        image: '',
        banner: '',
        description: 'It\'s time to shut down the biggest weekend in Durban with a fire lineup! A TV Spot for Channel O\'s Pop Bottles Durban July event.',
        video: 'https://www.youtube.com/embed/JuhNnnJ7CsU',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Pop Bottles Ski Festival Lesotho',
        image: '',
        banner: '',
        description: 'Pop Mabodlela, Pop Champagne at a ski resort in the mountain Kingdom of Lesotho! A TV Spot for Channel O\'s Pop Bottles Ski Festival Lesotho event.',
        video: 'https://www.youtube.com/embed/8zp4_Q5xa4c',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Sisters with Soul',
        image: '',
        banner: '',
        description: 'The Power of a Woman! A TV Spot for Mzansi Magic\'s Sisters with Soul event.',
        video: 'https://www.youtube.com/embed/1isdKOj9Coo',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'South Day Lifestyle Picnic',
        image: '',
        banner: '',
        description: 'We\'re putting back the style in lifestyle with a TV spot for Mzansi Magic\'s South Day Lifestyle Picnic event.',
        video: 'https://www.youtube.com/embed/TZY4VHFtt7c',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Therapy Sessions Annual Celebration',
        image: '',
        banner: '',
        description: 'Sing along to the sickest tunes and dance to your favorite beats. A TV spot for Mzansi Magic Music\'s Therapy Session\'s Annual Celebration.',
        video: 'https://www.youtube.com/embed/Fw9uPX2q7cY',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Mzansi Magic Music',
        title: 'Youth Endala All Black Edition',
        image: '',
        banner: '',
        description: 'Old Skool and New Skool go head to head! A TV Spot for the Youth Endala All Black Edition event.',
        video: 'https://www.youtube.com/embed/AQoSU0jvtHU',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Channel O',
        title: 'Global Citizen Festival',
        image: 'https://i3.ytimg.com/vi/7RJhHttnOGs/maxresdefault.jpg',
        banner: 'https://i3.ytimg.com/vi/7RJhHttnOGs/maxresdefault.jpg',
        description: 'Take a stand in realizing Madiba\'s vision for equality and the eradication of poverty. We created a TV spot promoting the Global Citizen Festival 2018 event and live broadcast.',
        video: {
            url: 'assets/video/Global_Citizen_Fest.mov',
            type: 'hosted',
            image: 'https://i3.ytimg.com/vi/7RJhHttnOGs/maxresdefault.jpg',
        },
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Channel O',
        title: 'YFM Life Fest',
        image: '',
        banner: '',
        description: 'Put the Y back into your life. A YFM celebration like no other! A TV spot for the YFM LYFE Fest event.',
        video: 'https://www.youtube.com/embed/UJBDf70wkKc',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Channel O',
        title: 'Old Skool Sundays VOL 2',
        image: '',
        banner: '',
        description: '',
        video: 'https://www.youtube.com/embed/6iYrlDszVd4',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Channel O',
        title: 'Capsule Fest',
        image: '',
        banner: '',
        description: 'Channel O gives you exclusive insight into all things culture, we go into the scene, through and behind the scene at the Capsule Fest to unbox everything lit about music and culture right now.',
        video: 'https://www.youtube.com/embed/2_FHNA5uZsQ',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: 'Channel O',
        title: 'AKA Orchestra',
        image: '',
        banner: '',
        description: '',
        video: 'https://www.youtube.com/embed/X2DxKYvKwDg',
        category: 'event',
        date: '2019-10-01',

    },
    {
        client: '',
        title: 'Mzansi Comedy Nights',
        image: 'https://i3.ytimg.com/vi/vZwGEaZ_RHA/hqdefault.jpg',
        banner: 'https://i3.ytimg.com/vi/vZwGEaZ_RHA/hqdefault.jpg',
        description: '',
        video: 'https://www.youtube.com/embed/vZwGEaZ_RHA',
        category: 'event',
        date: '2019-11-01',

    },
    {
        client: '',
        title: 'Veuve All White Lifestyle Picnic',
        image: '',
        banner: '',
        description: 'Ashi Nthwela!! It\'s time for the most elite lifestyle party with good food, good music and good vibes all round! A TV spot for DJ Sumbody\'s 2nd Annual Veuve All White Lifestyle Picnic in association with 1 Magic!',
        video: 'https://www.youtube.com/embed/uUfD2CxAfQM',
        category: 'event',
        date: '2019-11-01',

    },
    {
        client: '',
        title: 'All a Woman Needs Festival',
        image: '',
        banner: '',
        description: 'All roads lead to Mall of Africa for a day filled with all things fashion and beauty and an entertainment lineup with SA\'s female sensations. A TV spot for True Loves All a Woman Needs Festival.',
        video: 'https://www.youtube.com/embed/MuhaunhZcOI',
        category: 'event',
        date: '2019-11-01',

    },
    {
        client: '',
        title: 'Miller Music Drop',
        image: '',
        banner: '',
        description: '',
        video: 'https://www.youtube.com/embed/JuVd8zv1i00',
        category: 'event',
        date: '2019-11-01',

    },
    {
        client: '',
        title: 'Friends of Amstel SA',
        image: '',
        banner: '',
        description: 'A Timeless festival of good food, good friends and good music. A TV Spot for Channel O\'s Friends of Amstel SA event.',
        video: 'https://www.youtube.com/embed/BKOI002aluk',
        category: 'event',
        date: '2020-01-22',

    },
    {
        client: 'Channel O',
        title: 'Music is King',
        image: 'assets/thumbs/music_is_king_tn.jpg',
        banner: 'assets/img/music_is_king.jpg',
        description: 'The international music extravaganza is back!! A TV Spot for Channel O\'s Music is King concert.',
        category: 'content',
        date: '2020-01-22',
        video_campaigns: {
            content: [
                {
                    video: {
                        url: 'https://www.youtube.com/embed/T1EId_0iOCw?rel=0',
                        type: 'stream',
                    }
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/g5bCu5Qjhds?rel=0',
                        type: 'stream',
                    }
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/BZSkWgQIvZI?rel=0',
                        type: 'stream',
                    }
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/wfXqKfEOPhg?rel=0',
                        type: 'stream',
                    }
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/HjHi-erj1Ew?rel=0',
                        type: 'stream',
                    }
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/4tvsnfj_8gg?rel=0',
                        type: 'stream',
                    }
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/LvjRI_9GLK0?rel=0',
                        type: 'stream',
                    }
                },
            ]
        },
        template: 'content',
    },
    {
        client: '',
        title: 'G.H Mumm',
        image: '',
        banner: '',
        description: 'We celebrate the new year in grand style with exquisite cuisines, 5 star hospitality and beautiful view. A TV Spot for 1 Magic\'s GH Mumm event.',
        video: 'https://www.youtube.com/embed/cm3daXypdrY',
        category: 'event',
        date: '2020-01-22',

    },
    {
        client: '',
        title: 'Mogwase New Years Celebration',
        image: '',
        banner: '',
        description: 'Its time for the ultimate music experience. A TV Spot for The Mogwase New Years Celebration.',
        video: 'https://www.youtube.com/embed/suVQd5Pt0AE',
        category: 'event',
        date: '2020-01-22',

    },
    {
        client: '',
        title: 'Hunters Green Room Sessions JHB',
        image: '',
        banner: '',
        description: 'Hunter\'s Green Room brings you a musical night like no other, filled with tons of experiences, ice cold Hunter\'s and delicious food on the house! A TV spot for Channel O\'s Hunters Green Room Johannesburg event.',
        video: 'https://www.youtube.com/embed/CaPNczs8BH0',
        category: 'event',
        date: '2020-01-22',

    },
    {
        client: '',
        title: 'Hunters Green Room Sessions DBN',
        image: '',
        banner: '',
        description: 'Hunter\'s Green Room brings you a musical night like no other, filled with tons of experiences, ice cold Hunter\'s and delicious food on the house! A TV spot for Channel O\'s Hunters Green Room Durban event.',
        video: 'https://www.youtube.com/embed/_zFD5UgYCNo',
        category: 'event',
        date: '2020-01-22',

    },
    {
        client: '',
        title: 'Hunters Green Room Sessions CPT',
        image: '',
        banner: '',
        description: 'Hunter\'s Green Room brings you a musical night like no other, filled with tons of experiences, ice cold Hunter\'s and delicious food on the house! A TV spot for Channel O\'s Hunters Green Room Cape Town event.',
        video: 'https://www.youtube.com/embed/CkNkXzBRhtw',
        category: 'event',
        date: '2020-01-22',

    },
    {
        client: '',
        title: 'Hunters Green Room Sessions PTA',
        image: '',
        banner: '',
        description: 'Hunter\'s Green Room brings you a musical night like no other, filled with tons of experiences, ice cold Hunter\'s and delicious food on the house! A TV spot for Channel O\'s Hunters Green Room Pretoria event.',
        video: 'https://www.youtube.com/embed/YkX4toQyk68',
        category: 'event',
        date: '2020-01-22',

    },
    {
        client: '',
        title: 'Bacardi Holiday Club Day 1',
        image: '',
        banner: '',
        description: 'It’s time for the All-Inclusive music festival where entertainment is the top priority. A TV spot for Channel O\'s Bacardi Holiday Club Day 2 event.',
        video: 'https://www.youtube.com/embed/u0-J4IDmImw',
        category: 'event',
        date: '2020-01-22',

    },
    {
        client: '',
        title: 'Bacardi Holiday Club Day 2',
        image: 'https://i3.ytimg.com/vi/0J9nu1xwtcQ/hqdefault.jpg',
        banner: '',
        description: 'It\'s time for the All-Inclusive music festival where entertainment is the top priority. A TV spot for Channel O\'s Bacardi Holiday Club Day 2 event.',
        video: 'https://www.youtube.com/embed/0J9nu1xwtcQ',
        category: 'event',
        date: '2020-01-22',

    },
    {
        client: 'Channel O',
        title: 'Capsule Fest 2019',
        image: 'https://i3.ytimg.com/vi/ec5qMzFruUI/maxresdefault.jpg',
        banner: '',
        description: 'It\'s time to celebrate all things culture with the 3rd Annual Capsule Fest. A TV spot for Channel O\'s Capsule Fest concert.',
        category: 'content',
        date: '2019-09-15',
        video_campaigns: {
            video: {
                url: 'https://www.youtube.com/embed/ec5qMzFruUI?rel=0',
                type: 'stream',
            },
            content: [
                {
                    image: 'assets/img/24_01_2020_DBN Gogo.gif'
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/bzuNrgEBrSo?rel=0',
                        type: 'stream',
                    }
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/zNsJzw6qN3s?rel=0',
                        type: 'stream',
                    }
                },
                {
                    image: 'assets/img/24_01_2020_Gigi_2.gif'
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/y1rvd4kC_bo?rel=0',
                        type: 'stream',
                    }
                },
                {
                    image: 'assets/img/24_01_2020_MK_Fresh.gif'
                },
                {
                    image: 'assets/img/24_01_2020_Tshego.gif'
                },
                {
                    video: {
                        url: 'https://www.youtube.com/embed/3hRDfcMKB-I?rel=0',
                        type: 'stream',
                    }
                },
            ]
        },
        template: 'content',

    },
    {
        client: '',
        title: 'Fact Durban Rocks Live Broadcast',
        image: 'https://i3.ytimg.com/vi/OQ3MiVCT1ic/hqdefault.jpg',
        banner: '',
        description: 'Yakokota i\'Piano this December on Channel O! A TV spot for Channel O\'s live Broadcast of the Fact Durban Rocks event.',
        video: 'https://www.youtube.com/embed/OQ3MiVCT1ic',
        category: 'show',
        date: '2020-01-22',

    },
    {
        client: '',
        title: 'Hip Hop Theme Sting',
        image: '',
        banner: '',
        description: 'A TV Spot for Channel O\'s Hip Hop theme sting.',
        video: 'https://www.youtube.com/embed/6FwthRkUQbU',
        category: 'show',
        date: '2020-01-22',

    },
    {
        client: '',
        title: 'Channel O Summer Theme',
        image: '',
        banner: '',
        description: 'Music Over Everything! A TV spot for Channel O\'s Summer Theme',
        video: 'https://www.youtube.com/embed/xgP5UpsqF1c',
        category: 'show',
        date: '2020-01-22',

    },
    {
        client: '',
        title: 'Amapiano Theme Sting',
        image: '',
        banner: '',
        description: 'A TV Spot for Channel O\'s Amapiano theme sting.',
        video: 'https://www.youtube.com/embed/lPnr98y5OP4',
        category: 'show',
        date: '2020-01-22',

    },
    {
        client: '',
        title: 'House Theme',
        image: '',
        banner: '',
        description: 'A TV Spot for Channel O\'s House/ Gqom theme.',
        video: 'https://www.youtube.com/embed/VP3l6zwXqSM',
        category: 'show',
        date: '2020-01-22',

    },
    {
        client: '',
        title: 'Shimza\'s One Man Show',
        image: '',
        banner: '',
        description: 'We bid farewell to the decade with the biggest musical concert yet! A TV spot for Channel O\'s Shimza One Man Show finale event.',
        video: 'https://www.youtube.com/embed/GJE53tfgNos',
        category: 'event',
        date: '2020-01-22',

    },
    {
        client: '',
        title: 'Africans Unite Concert',
        image: '',
        banner: '',
        description: 'One continent, One people, One Love! It\'s time for the biggest drop! A TV spot for Channel O\'s Africans Unite Concert.',
        video: 'https://www.youtube.com/embed/C8PyhLY2l_E',
        category: 'event',
        date: '2020-01-22',

    },
    {
        client: '',
        title: 'Channel O Hip Hop Theme',
        image: '',
        banner: '',
        description: 'Hip Hop Over Everything! A TV Spot for Channel O\'s Hip Hop theme sting.',
        video: 'https://www.youtube.com/embed/enHWPXySKWQ',
        category: 'show',
        date: '2020-01-22',

    },
    {
        client: '',
        title: 'Channel O Amapiano Theme',
        image: '',
        banner: '',
        description: 'Piano Over Everything! A Tv spot for Channel O\'s Amapiano theme.',
        video: 'https://www.youtube.com/embed/9AGB_vzkNNE',
        category: 'show',
        date: '2020-01-22',

    },
    {
        client: 'Mzansi Magic',
        title: 'INXEBA - The Wound',
        image: 'assets/thumbs/2020_03_17_inxeba_tn.jpg',
        banner: 'assets/img/2020_03_17_inxeba.jpg',
        description: '',
        video: '',
        category: 'advert',
        date: '2020-03-17',
        img_campaigns: [
            'assets/img/2020_03_17_inxeba_1.jpg',
            'assets/img/2020_03_17_inxeba_2.jpg',
        ],

    },
    {
        client: 'Mzansi Magic',
        title: 'Mzansi Comedy Nights',
        image: '',
        banner: '',
        description: 'Mzansi Stnd up! It\'s time for non stop laughter with Comedy Wednesdays. A TV spot for Mzansi Magic\'s Mzansi Comedy Nights.',
        video: 'https://www.youtube.com/embed/rwjGoYiKH24',
        category: 'event',
        date: '2020-01-22',

    },
    {
        client: 'Mzansi Magic',
        title: 'Magic Music Sessions',
        image: '',
        banner: '',
        description: 'Vertex Events and Mzansi Magic Music Celebrates 5 years of Magic Music Sessions with epic nights of music featuring grammy award winning artists Musiq Soulchild and Tasha Cobbs Leanord. A TV spot for Mzansi Magic Music\'s Magic Music Sessions event.',
        video: 'https://www.youtube.com/embed/lPMnxtZ593A',
        category: 'event',
        date: '2020-01-22',

    },
    {
        client: 'Mzansi Magic',
        title: 'Boys to Men SA Tour',
        image: '',
        banner: '',
        description: 'It\'s time for a timeless experience with the Grammy Award Winning RnB group, Boys to Men. A TV spot for 1Magics Boys to Men SA Tour.',
        video: 'https://www.youtube.com/embed/92Mr5h4F4w4',
        category: 'event',
        date: '2020-01-22',

    },
    {
        client: 'Mzansi Magic',
        title: 'Deep in the City Soweto',
        image: '',
        banner: '',
        description: 'Get ready for a 1 night musical experience featuring SA\'s world class DJ\'s. A TV spot for Moja Cafe\'s Deep in the City Soweto event.',
        video: 'https://www.youtube.com/embed/DYuwzPcNW7s',
        category: 'event',
        date: '2020-01-22',

    },
    {
        client: 'VBank',
        title: 'The V Digital Bank App',
        image: 'https://i3.ytimg.com/vi/zwo1HD3Cs4c/maxresdefault.jpg',
        banner: 'https://i3.ytimg.com/vi/zwo1HD3Cs4c/maxresdefault.jpg',
        description: '<p>The V Digital Bank App is powered by the VFD Microfinance bank, a Lagos-based financial services institution operating in the Microfinance banking sector. The V looks to be the company’s approach to wooing a much younger, banked audience. The brief was to create fun and insightful Digital Ad’s on what V is all about! </p><p>We produced a Launch Video, Explainer Video’s and Five Feature Ad’s. All these graphic and shot content in one unified package. The V, Do More! </p>',
        video: 'https://www.youtube.com/embed/zwo1HD3Cs4c',
        category: 'advert',
        date: '2020-07-28',
        video_campaigns: [
            'https://www.youtube.com/embed/ZNyUKl7R_HE',
            'https://www.youtube.com/embed/R7fmI1IaNK0',
            'https://www.youtube.com/embed/XwImLsXpyAQ',
            'https://www.youtube.com/embed/FanGcbNBHAw',
            'https://www.youtube.com/embed/VrQzJG99EQE',
            'https://www.youtube.com/embed/UWY27TMOtMg',
        ],
    },
    {
        client: 'VBank',
        title: 'The V Digital Bank App',
        image: 'https://i3.ytimg.com/vi/R7fmI1IaNK0/maxresdefault.jpg',
        banner: 'https://i3.ytimg.com/vi/R7fmI1IaNK0/maxresdefault.jpg',
        description: '<p>The V Digital Bank App is powered by the VFD Microfinance bank, a Lagos-based financial services institution operating in the Microfinance banking sector. The V looks to be the company’s approach to wooing a much younger, banked audience. The brief was to create fun and insightful Digital Ad’s on what V is all about! </p><p>We produced a Launch Video, Explainer Video’s and Five Feature Ad’s. All these graphic and shot content in one unified package. The V, Do More! </p>',
        video: 'https://www.youtube.com/embed/R7fmI1IaNK0',
        category: 'advert',
        date: '2020-07-28',
    },
    {
        client: 'VBank',
        title: 'The V Digital Bank App',
        image: 'https://i3.ytimg.com/vi/XwImLsXpyAQ/maxresdefault.jpg',
        banner: 'https://i3.ytimg.com/vi/XwImLsXpyAQ/maxresdefault.jpg',
        description: '<p>The V Digital Bank App is powered by the VFD Microfinance bank, a Lagos-based financial services institution operating in the Microfinance banking sector. The V looks to be the company’s approach to wooing a much younger, banked audience. The brief was to create fun and insightful Digital Ad’s on what V is all about! </p><p>We produced a Launch Video, Explainer Video’s and Five Feature Ad’s. All these graphic and shot content in one unified package. The V, Do More! </p>',
        video: 'https://www.youtube.com/embed/XwImLsXpyAQ',
        category: 'advert',
        date: '2020-07-28',
    },
    {
        client: 'VBank',
        title: 'The V Digital Bank App',
        image: 'https://i3.ytimg.com/vi/FanGcbNBHAw/maxresdefault.jpg',
        banner: 'https://i3.ytimg.com/vi/FanGcbNBHAw/maxresdefault.jpg',
        description: '<p>The V Digital Bank App is powered by the VFD Microfinance bank, a Lagos-based financial services institution operating in the Microfinance banking sector. The V looks to be the company’s approach to wooing a much younger, banked audience. The brief was to create fun and insightful Digital Ad’s on what V is all about! </p><p>We produced a Launch Video, Explainer Video’s and Five Feature Ad’s. All these graphic and shot content in one unified package. The V, Do More! </p>',
        video: 'https://www.youtube.com/embed/FanGcbNBHAw',
        category: 'advert',
        date: '2020-07-28',
    }
    ,{
        client: 'Channel O',
        title: 'Channel O Manifesto',
        image: '',
        banner: '',
        description: '',
        video: 'https://www.youtube.com/embed/pqOSodLSQMI',
        category: 'show',
        date: '2020-07-28',

    },{
        client: 'Channel O',
        title: 'Channel O Youth Month',
        image: 'https://img.youtube.com/vi/HJWpDD6f46U/maxresdefault.jpg',
        banner: '',
        description: '',
        video: 'https://www.youtube.com/embed/HJWpDD6f46U',
        category: 'show',
        date: '2020-07-28',

    },{
        client: 'Gauteng Department of Health',
        title: 'Department of Health - Year of the Nurse and Midwife',
        image: 'assets/video/health_image.jpg',
        banner: '',
        description: 'We Originate for Good as we support our nation in the fight against COVID - 19. A Health Department tribute video to the nurses and midwives fighting against the Corona Virus.',
        category: 'content',
        date: '2020-08-11',
        video_campaigns: {
            video: {
                url: 'assets/video/health_1.mp4',
                type: 'hosted',
                image: 'assets/video/health_image_logo.jpg',
            },
            content: [
                {
                    video: {
                        url: 'assets/video/health_4.mp4',
                        type: 'hosted',
                        image: 'assets/video/health_4.jpg',
                    },
                },
                {
                    image: 'assets/gallery/1.jpeg',
                },
                {
                    image: 'assets/gallery/2.jpeg',
                },{
                    video: {
                        url: 'assets/video/health_2.mp4',
                        type: 'hosted',
                        image: 'assets/video/health_2.jpg',
                    },
                },
                {
                    video: {
                        url: 'assets/video/health_3.mp4',
                        type: 'hosted',
                        image: 'assets/video/health_3.jpg',
                    },
                },
                {
                    image: 'assets/gallery/3.jpeg',
                },
                {
                    image: 'assets/gallery/4.jpeg',
                },
                {
                    image: 'assets/gallery/5.jpeg',
                },
                {
                    image: 'assets/gallery/6.jpeg',
                },
                {
                    image: 'assets/gallery/7.jpeg',
                },
                {
                    image: 'assets/gallery/8.jpeg',
                },
                {
                    image: 'assets/gallery/9.jpeg',
                },
            ]
        },
        template: 'content',
    },
    {
        client: 'Channel O',
        title: 'CHO Woman\'s Month promo',
        image: 'https://img.youtube.com/vi/9hm2JvjDt8M/maxresdefault.jpg',
        banner: '',
        description: '',
        video: 'https://www.youtube.com/embed/9hm2JvjDt8M',
        category: 'show',
        date: '2020-09-10',
    },
    {
        client: 'Mzansi Magic',
        title: 'Kwaito theme',
        image: 'https://img.youtube.com/vi/KAbcyNB6GJw/maxresdefault.jpg',
        banner: '',
        description: 'Zwakala nganeno as we take you back to the days of ding-dong and war was jy. Groova with us cause agekho u\'Gogo, only the best kwaito this festive season. A TV spot for Mzansi Music\'s Kwaito theme',
        video: 'https://www.youtube.com/embed/KAbcyNB6GJw',
        category: 'show',
        date: '2021-04-14',
    },
    {
        client: 'Mzansi Magic',
        title: 'House theme',
        image: 'https://img.youtube.com/vi/iaj4BcKfPrs/maxresdefault.jpg',
        banner: '',
        description: 'Party with us this festive and make your home the place to be. We bring you the finest tunes that will bring you into the groove. A TV spot for Mzansi Music\'s House theme.',
        video: 'https://www.youtube.com/embed/iaj4BcKfPrs',
        category: 'show',
        date: '2021-04-14',
    },
    {
        client: 'Mzansi Magic',
        title: 'Gospel theme',
        image: 'https://img.youtube.com/vi/FpBRwX6HXiI/maxresdefault.jpg',
        banner: '',
        description: 'This festive season we anoint you with the best gospel that will uplift your spirits. Be part of the celebration and turn your living room into a place of worship. A TV spot for Mzansi Music\'s Gospel theme.',
        video: 'https://www.youtube.com/embed/FpBRwX6HXiI',
        category: 'show',
        date: '2021-04-14',
    },
    {
        client: 'Mzansi Magic',
        title: 'Afro-pop theme',
        image: 'https://img.youtube.com/vi/iQq1ADzxxw8/maxresdefault.jpg',
        banner: '',
        description: 'Soothe your soul with the best Afro Pop this festive season, sit back and enjoy the voices of that sweet Afro sound. A TV spot for Mzansi Magic Music’s Afro Pop theme.',
        video: 'https://www.youtube.com/embed/iQq1ADzxxw8',
        category: 'show',
        date: '2021-04-14',
    },
];
