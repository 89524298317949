import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NavItem } from './../shared/nav-item';
import { ProductService } from '../shared/product.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  pages: NavItem[] = [
    {
      route: 'work',
      label: 'Work',
    },
    {
      route: 'about',
      label: 'About',
    },
    {
      route: 'contact',
      label: 'Contact',
    },
    {
      route: 'intern',
      label: 'Intern',
    },
    {
      route: 'forgood',
      label: 'Originate For Good',
    },
  ];

  _location: Location;

  showNav: boolean;
  isNavbarCollapsed: boolean;
  current: any;
  total: number;

  constructor(
    location: Location,
    private productService: ProductService,
    private router: Router,
  ) {
    this._location = location;
  }

  ngOnInit() {
    this.productService.getProductTotal()
    .subscribe(total => this.total = total);
    this.showNav = false;
    this.isNavbarCollapsed = true;
  }

  activePage() {
    let active = this._location.path();
    if(active.indexOf('detail') >= 0) {
      let params = active.split('/');
      this.current = Number(params[2]);
      this.showNav = true;
      return 'work';
    }
    this.showNav = false;
    return this._location.path().replace('/','');
  }
  toggleMenu() {
    this.isNavbarCollapsed = true;
  }
  viewPrevProduct() {
    let page = (this.current === (this.total - 1)) ? 0 : this.current + 1;
    let url: string = "/detail/" + page;
    this.router.navigateByUrl(url);
  }
  viewNextProduct() {
    let page = (this.current === 0) ? (this.total - 1) : this.current - 1;
    let url: string = "/detail/" + page;
    this.router.navigateByUrl(url);
  }
  getShowNav() {
    return this.showNav;
  }
}
