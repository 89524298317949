import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router'; 
import { ProductService } from '../shared/product.service';
import { Product } from '../shared/product';
import { NavItem } from '../shared/nav-item';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import videojs from 'video.js';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./work.component.scss']
})
export class WorkComponent implements OnInit {

  products: Product[];
  productsShow: Product[];
  productsEvent: Product[];
  productsAdvert: Product[];
  productsContent: Product[];
  closeResult: string;

  categories: NavItem[] = [
    {
      route: 'all',
      label: 'All',
    },
    {
      route: 'show',
      label: 'Broadcast Branding',
    },
    {
      route: 'advert',
      label: 'Digital Campaigns',
    },
    {
      route: 'event',
      label: 'Entertainment Marketing',
    },
    {
      route: 'content',
      label: 'Content',
    },
  ];

  selected: NavItem;

  constructor(
    private productService: ProductService,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.getProducts();
    this.selected = this.categories[0];
  }

  loadVideo() {
    // ID with which to access the template's video element
    const el = 'video_main';
    const els = document.getElementsByClassName('video-js');
    for (let i = 0; i < els.length; i++) {
      videojs(els[i], {}, function () {

        // Store the video object
        const myPlayer = this, id = myPlayer.id();
        // Make up an aspect ratio
        const aspectRatio = 264 / 640;
        // internal method to handle a window resize event to adjust the video player
        function resizeVideoJS() {
          const width = document.getElementById(id).parentElement.offsetWidth;
          if (!!myPlayer) {
            myPlayer.width(width);
            myPlayer.height(width * aspectRatio);
          }
        }
        // Initialize resizeVideoJS()
        resizeVideoJS();
        // Then on resize call resizeVideoJS()
        // window.onresize = resizeVideoJS;
      });
    }
  }

  getProducts(): void {
    this.productService.getProducts()
      .subscribe(products => {
        this.products = (products[0].id === 0) ? products.reverse() : products;
        for (let i = 0; i <= this.products.length; i++) {
          if (this.products[i] && !this.products[i].image && this.products[i].video) {
            this.products[i].image = this.productService.getImageFromLink(this.products[i].video);
          }
        }
        this.productsShow = products.filter(item => item.category === 'show');
        this.productsEvent = products.filter(item => item.category === 'event');
        this.productsAdvert = products.filter(item => item.category === 'advert');
        this.productsContent = products.filter(item => item.category === 'content');
      });
  }

  viewProduct(id: number) {
    let url: string = "/detail/" + id
    this.router.navigateByUrl(url);
  }

  openModal(content) {
    this.modalService.open(content, { centered: true, size: 'lg' });
    this.loadVideo();
  }
}
