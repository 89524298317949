import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ProductService } from '../shared/product.service';
import { Product } from '../shared/product';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  product: Product;
  current_url: SafeUrl;
  video_urls: Array<SafeUrl>;
  type: String;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private productService: ProductService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.getProduct();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          this.getProduct();
          return;
      }
  });
  }

  getProduct(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.productService.getProductById(id)
      .subscribe(item => {
        if (!!item) {
          item.template = (!!item.template) ? item.template : '';
          this.product = item;
          if (this.product.video && this.product.video.type === 'hosted') {
            this.current_url = this.productService.cleanURL(`${this.product.video.url}`);
            this.type = 'hosted';
          } else {
            this.current_url = this.productService.cleanURL(`${this.product.video}?rel=0`);
            this.type = 'stream';
          }
          this.video_urls = [];
          if (this.hasOtherVideos()) {
            for(let i = 0; i < this.product.video_campaigns.length; i++) {
              this.video_urls.push(this.productService.cleanURL(`${this.product.video_campaigns[i]}?rel=0`));
            }
          }
          if (this.product && !this.product.image && this.product.video) {
            this.product.image = this.productService.getImageFromLink(this.product.video);
          }
          if (!this.product.banner) {
            this.product.banner = this.product.image;
          }
        }
      });
  }

  scrollToContent(): void {
    const el = document.getElementById('org-content');
    window.scroll({top: el.offsetTop, left: 0, behavior: 'smooth'});
  }

  hasOtherVideos(): Boolean {
    if (Boolean(this.product.video_campaigns)) {
      return (this.product.video_campaigns.length > 0);
    }
    return false;
  }
}
