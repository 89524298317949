import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NavItem } from '../shared/nav-item';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  _location: Location;
  pages: NavItem[] = [
    {
      route: 'work',
      label: 'Work',
    },
    {
      route: 'about',
      label: 'About',
    },
    {
      route: 'contact',
      label: 'Contact',
    },
  ];

  constructor(location: Location) { this._location = location; }

  ngOnInit() {
  }

  activePage() {
    return this._location.path().replace('/','');
  }
}
