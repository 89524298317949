<section class="first-section">
  <div class="p-3 p-md-5 w100 orgn-bg-dark text-white  align-items-center justify-content-center d-flex">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <h1 class="text-center">Privacy Policy</h1>
          <span class="orgn-divider orgn-bg-white my-5" animateOnScroll animationName="visible"></span>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container pt-5 pb-5">
    <div class="row">
      <div class="col-12">
        <p>
            Our Privacy Policy applies to information we collect when you use originators.tv and any other websites, social media platforms including, without limitation YouTube, Vevo, Spotify, SoundCloud, Facebook, Instagram, Twitter, Snapchat and LinkedIn and services that are owned and/or controlled by us (collectively, the "Website"). Our Privacy Policy is designed to assist you in understanding how Originators TV (PTY) Ltd ("Company", "we," or "us") collects and uses the personal information you provide to us and to assist you in making informed decisions when using our Website and our products and services.
        </p>
        <h3>Your Consent</h3>
        <p>
          By using our Website, you consent to the collection and use of information by us as set forth in this Privacy Policy. If we decide to change our Privacy Policy, we will post those changes on this page so that you will always be aware of what information we collect, how we use it, and under what limited circumstances, if ever, we disclose it. Continued access or use of our Website shall constitute your express acceptance of any modifications to this Privacy Policy.
        </p>
        <p>
          This Privacy Policy does not cover information that you submit on other websites, even if we communicate with you on those sites. For example, if you post something on YouTube, Facebook, Instagram, Twitter, Snapchat and LinkedIn, that information is governed by the privacy policies on those websites, and is not governed by this policy.
        </p>
        <h3>
            What Information Do We Collect?
        </h3>
        <p>
            When you visit our Website you may provide us with two types of information: Personal Information you knowingly choose to disclose that is collected on an individual basis and information collected on an aggregate basis as you and others browse our Website. "Personal Information" is information that can be used to identify you (whether alone or in combination) such as your first and last name, email address, zip code/post code, billing address, shipping address, phone number, age, date of birth, social media handles and any other information you choose to provide.
        </p>
        <p>
            We engage third parties, such as Google Analytics, to provide analytics about the use of our Website. These entities may use cookies, web beacons, pixels, embedded scripts, and other technologies to automatically collect information about your use of the Website, such as your IP address, web browser, pages viewed, time spent on pages, links clicked, and conversion information.
        </p>
        <p>
            We receive information about consumers from third parties to enable targeted advertising on the website(s) and application(s) that we support. The collection and use of that data is governed by the privacy policy and legal terms of the data collector and the website using the data; it is not governed by Originators TV (PTY) Ltd.
        </p>
        <h3>
            Email Information
        </h3>
        <p>If you choose to correspond with us through email, we may retain the content of your email messages together with your email address and our responses.</p>
        <h3>How Do We Use the Information That You Provide to Us?</h3>
        <p>
            We may use the User Account Information (defined in the Terms) to respond to your submission to further discuss whether Company and User may want to enter into a separate agreement for Company’s services. Occasionally, we may also use the information we collect to notify you about important changes to our Website, published articles, and blog posts we think are of interest to you. Emails may be shared with third-party vendors such as MailChimp and SurveyMonkey to perform certain business and marketing functions. You may notify us at any time if you do not wish to receive these offers by emailing us at the address listed below.
        </p>
        <h3>What Are Cookies?</h3>
        <p>
            A cookie is a very small text document, which often includes an anonymous unique identifier. When you visit a site, that site's computer asks your computer for permission to store this file in a part of your hard drive specifically designated for cookies. Each site can send its own cookie to your browser if your browser's preferences allow it.
        </p>
        <p>
            Note that your browser settings may allow you to automatically transmit a "Do Not Track" signal to sites and online services you visit. There is no consensus among industry participants as to what "Do Not Track" means in this context. Like many websites and online services, we currently do not alter our practices when we receive a "Do Not Track" signal from a visitor’s browser. To find out more about "Do Not Track," you may wish to visit <a href="http://www.allaboutdnt.com" target="_blank">http://www.allaboutdnt.com</a>.
        </p>
        <h3>How Do We Use Information We Collect from Cookies?</h3>
        <p>
            As you browse our Website, the Website uses its cookies to differentiate you from other visitors. In some cases, we may use cookies to prevent you from seeing unnecessary advertisements or requiring you to log in more than is necessary for security. Cookies, in conjunction with our Website server's log files, allow us to calculate the aggregate number of people visiting our Website and which parts of the Website are most popular. This helps us gather feedback in order to constantly improve our Website and better serve our visitors. Cookies do not allow us to gather any personal information about you and we do not generally store any personal information that you provided to us in your cookies.
        </p>
        <h3>Children</h3>
        <p>
            This web site is not intended or designed to attract children under the age of 18. We do not knowingly collect personal information from or about any person under the age of 18. If you are under 18 years old and wish to ask a question or use this site in any way which requires you to submit your personal information, please get your parent or guardian to do so on your behalf. Likewise, if you are a parent or guardian and think your child may have submitted their personal information to us by mistake, please let us know, <a href="mailto:hello@originators.tv">hello@originators.tv</a>, and we will delete that data.
        </p>
        <h3>
            Data Security
        </h3>
        <p>
            We take reasonable steps to have physical, electronic, and procedural safeguards in place to help protect your Personal Information. This may include, in appropriate circumstances, use of Secure Socket Layer ("SSL") encryption. Unfortunately, no data transmission over the Internet can be guaranteed to be 100% secure. As a result, while we strive to protect your Personal Information, we cannot guarantee or warrant the security of any information collected through our Website.
        </p>
        <h3>
            Contact Us
        </h3>
        <p>
            If you wish to exercise any of your rights in relation to your personal information or if you have any queries about how we use your personal information, please let us know by contacting us via the contact section of our website or via 
            <a href="mailto:hello@originators.tv">hello@originators.tv</a>
        </p>
      </div>
    </div>
  </div>
</section>