import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { Location } from '@angular/common';
import videojs from 'video.js';

@Component({
  selector: 'app-originate-for-good',
  templateUrl: './originate-for-good.component.html',
  styleUrls: ['./originate-for-good.component.scss']
})
export class OriginateForGoodComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // ID with which to access the template's video element
    const el = 'video_main';
    const els = document.getElementsByClassName('video-js');
    for (let i = 0; i < els.length; i++) {
      videojs(els[i], {}, function () {

        // Store the video object
        const myPlayer = this, id = myPlayer.id();
        // Make up an aspect ratio
        const aspectRatio = 264 / 640;
        // internal method to handle a window resize event to adjust the video player
        function resizeVideoJS() {
          const width = document.getElementById(id).parentElement.offsetWidth;
          if (!!myPlayer) {
            myPlayer.width(width);
            myPlayer.height(width * aspectRatio);
          }
        }
        // Initialize resizeVideoJS()
        resizeVideoJS();
        // Then on resize call resizeVideoJS()
        // window.onresize = resizeVideoJS;
      });
    }
  }
}
