<section class="first-section orgn-half orgn-hm-landing">
  <div class="orgn-half p-3 p-md-5 w100 orgn-bg-dark text-white  align-items-center justify-content-center d-flex">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <h1 class="text-center hide-on-init" animateOnScroll animationName="animated fadeInDown">Originators
            Internship Program</h1>
          <span class="orgn-divider orgn-bg-white my-4" animateOnScroll animationName="visible"></span>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-6">
          <p class="hide-on-init text-center" animateOnScroll animationName="animated fadeIn">We support talented young
            creatives to develop the critical skills needed to elevate their careers.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="orgn-half orgn-hm-landing-3">
  <div class="orgn-half text-white w100 align-items-center d-flex justify-content-center">
    <div class="row justify-content-center">
      <div class="col-12 py-5">
        <h3 class="text-center hide-on-init mb-4" animateOnScroll animationName="animated fadeInRight">Here are some of the skills you can develop</h3>
        <div class="d-flex flex-wrap justify-content-center">
          <div animateOnScroll animationName="animated fadeInUp" class="ng-program p-3 m-2 rounded"
            *ngFor="let program of programs; index as i">{{program}}</div>
        </div>
        <span class="orgn-divider orgn-bg-white my-4" animateOnScroll animationName="visible"></span>
        <p animateOnScroll animationName="animated fadeInUp" class="d-flex align-items-center ng-large-txt  justify-content-center pt-3"><a href="mailto:intern@originators.tv" class="btn btn-outline-light">Click here</a> <span class="pl-2">to apply. Send us your CV.</span></p>
      </div>
    </div>
  </div>
</section>
<section class="align-items-center justify-content-center">
  <div class="row justify-content-center">
    <div class="col-12 col-md-8">
      <h3 class="text-center hide-on-init m-4">Testimonials</h3>
      <span class="orgn-divider my-3" ></span>
      <ngb-carousel class="pb-3 carousel slide carousel-fade">
        <ng-template ngbSlide>
          <div class="ng-quote text-center p-3">
            <p class="ng-qoute-txt">"Being at Originators TV has shaped my career in a way I never thought possible. Before Originators, I knew nothing about video editing, but with my time spent, under the guidance of Keith Bete (Executive Producer & Founder). I can today confidently say, I have edited TV Spots, not only for BET, Mzansi Magic Music, 1Magic, but I have also edited for the SABC and Moja Love. In short, I have had more than a perspective change, I have had a great career change."</p>
            <em class="ng-quote-em">- Sabelo Lethu Hlatshwayo.</em>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="ng-quote text-center p-3">
            <p class="ng-qoute-txt">"I Joined Originators TV on an internship basis coming from a TV background mainly News Technical department and my goal was to branch out into a more creative environment within the media industry, Originators TV afforded me the opportunity to create content through Promo Producing as well as video editing, my writing skills grew sharper, the best part is that I was able to work with top brands such as Channel O, BET, Trace Urban to name a few, working with an award winning creative such as the founder of Originators TV was an amazing opportunity and experience, Originators TV has it's finger on the pulse with the evolution of the creative space in Africa."</p>
            <em class="ng-qoute-em">- Reitumetse Montshiwa.</em>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="ng-quote text-center p-3">
            <p class="ng-qoute-txt">"<strong>Experience</strong> - Originators TV gave me a great and clear experience of how the television work through their work flow, knowledge and teamwork dynamics. The opportunity I received allowed me to be able to shift from a school learning environment to learn and understand what the industry is like on a day to day basis. It was a great learning experience."</p>
            <p class="ng-qoute-txt">"<strong>Grooming</strong> - One of the things I most appreciate about the company is the level of patience and how accommodative the staff and the environment is. They literally enjoy helping and teaching you how certain things are done in the different spheres of post production."</p>
            <p class="ng-qoute-txt">"<strong>Growth</strong> - I ended my internship with a clear understanding of the work flow of post production, the importance of learning and growing as a team and allowing oneself to learn and not walk into any environment thinking that you know it all. "</p>
            <em class="ng-qoute-em">- Noxolo Bobelo.</em>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
</section>
