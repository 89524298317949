<nav class="navbar navbar-expand-md shadow-sm fixed-top row justify-content-between align-items-center">
  <div class="col-3 col-md-4 orgn-social-media">
    <a href="https://www.youtube.com/channel/UCtmUG9GpGw9bDGNCr_uoq4w" target="_blank"><i class="icon-youtube1"></i></a>
    <a href="https://www.instagram.com/originators.tv/" target="_blank" class="mx-3"><i class="icon-instagram"></i></a>
  </div>
  <div class="col-6 col-md-4 text-center"><a class="navbar-brand" href="#"><img src="assets/logo_white_sm.svg" id="logo"
        class="img-fluid" alt="Originators TV"></a></div>
  <div class="col-3 d-flex d-md-none justify-content-end align-items-center">
    <button id="orgn-nav-icon" class="navbar-toggler" (click)="isNavbarCollapsed = !isNavbarCollapsed" [class.open]="!isNavbarCollapsed">
      <span></span>
      <span></span>
      <span></span>
    </button>
  </div>
  <div class="col-12 col-md-4 d-flex justify-content-end align-items-center">
    <div [ngbCollapse]="isNavbarCollapsed" class="navbar-collapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" *ngFor="let link of pages; index as i" (click)="toggleMenu()">
          <a class="nav-link" [class.active]="link.route === activePage()" routerLink="{{link.route}}">{{link.label}}</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="orgn-pager" [hidden]="!showNav">
  <button class="btn btn-link" (click)="viewPrevProduct()">previous</button>
  <button class="btn btn-link mx-3" (click)="viewNextProduct()">Next</button>
</div>
